import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      // Warnings:
      // 1. HTML tags cannot be used in all values, but mainly only in descriptions
      // 2. only simple tags without any attribute are allowed (see transKeepBasicHtmlNodesFor above)

      // for all select dropdowns
      select_create: 'Create: {{input}}',
      ////////////////////////////////
      // Panel
      ////////////////////////////////
      monitor_settings_success: 'Monitor was successfully added to {{area}}',
      rename_success: 'Area was successfully renamed to {{updatedName}}',
      add_monitor_button: 'Activate a meo monitor',
      toggle_colors: 'Colors',
      toggle_energy_saving: 'Power & data savings',
      toggle_location: 'Location',
      toggle_opening_hours: 'Opening hours',
      toggle_dcv: 'Ventilation Control',
      loading: 'Loading...',
      ////////////////////////////////
      // Add a monitor and Change area
      ////////////////////////////////
      // button
      scanner_card_start: 'Take the activation card from the monitor package and either:',
      scanner_card_scan: 'Scan the QR code below, or',
      scanner_card_fallback: 'Enter the activation code',
      // errors
      scan_invalid_qr_code: 'Invalid QR code, please enter the activation code below instead.',
      scan_permission_error:
        'Camera permission denied, please retry after allowing camera access or enter the activation code below.',
      scan_error: 'Camera cannot be used, please enter the activation code instead (Details: {{error}}).',
      cannot_be_blank: 'Cannot be blank.',
      cannot_be_same_area: 'Cannot be the same as current area "{{oldArea}}"',
      eight_chars_token:
        'Activation code must have 12 alphanumeric characters but is {{trimmed}}. Should not be confused with the monitor serial number.',
      bad_token_length: 'Activation code must have 12 alphanumeric characters but is {{trimmed}}.',
      required_rename_move: '"Rename the area" or "Move the monitor" option must be chosen.',
      // modal title
      change_area_modal_title_new: 'Activate a monitor',
      change_area_modal_title_update: '✎ Change area <em>{{area}}</em>',
      // fields
      token_label: 'Monitor activation code',
      token_description: 'The 12 alphanumeric characters are below the QR code on the monitor activation card.',
      token_placeholder: '1234 5678 9AbC',
      rename_move_label: 'Purpose',
      rename_move_description_intro: 'Select one of the two options below:',
      rename_move_description_rename_intro: ' option is used in either case:',
      rename_move_description_rename_1: 'typo: e.g. <em>Rom A</em> was set instead of <em>Room A</em>',
      rename_move_description_rename_2: 'area name changed: e.g. <em>Room 1</em> was renamed <em>Art Room</em>',
      rename_move_description_move: ' option is used to relocate the monitor to another area.',
      rename_label: 'Rename the area',
      move_label: 'Move the monitor',
      rename_area_label: 'Updated area name',
      rename_area_description: 'Current area name is <em>{{area}}</em>',
      area_label: 'Area name',
      area_description: 'The area for the monitor is typically a room name, e.g. Room 102.',
      area_placeholder_one: 'Enter a new area',
      area_placeholder_other: 'Enter a new area or choose an existing area',
      replace_area_label: 'Area name for the replaced monitor',
      replace_area_description_one:
        ' A new area for the replaced <code>{{monitorSerialNum}}</code> monitor currently in <em>{{area}}</em>.' +
        ' An area is typically a room name or might be for example <em>Unknown area for {{monitorSerialNum}}</em>.',
      replace_area_description_other:
        ' A new area or an existing area without a monitor for the replaced <code>{{monitorSerialNum}}</code> monitor currently in <em>{{area}}</em>.' +
        ' An area is typically a room name or might be for example <em>Unknown area for {{monitorSerialNum}}</em>.',
      replace_area_placeholder_one: 'Enter a new area',
      replace_area_placeholder_other: 'Enter a new area or choose an existing area',
      replace_area_default: 'Unknown area for {{monitorSerialNum}}',
      // Fake Demand Control
      dc_abbr: 'DCV',
      dc_abbr_title: 'Demand-Controlled Ventilation',
      dc_name_prefix: '',
      dc_name_suffix: '\u00A0name',
      dc_id_prefix: '',
      dc_id_suffix: '\u00A0identification',
      // boxes
      info_title: 'Graph data after you press OK',
      info_intro: 'For <code>{{monitorSerialNum}}</code> monitor:',
      info_rename_1: 'both previous and new data under <em>{{updatedName}}</em>',
      info_move_1: 'previous data under <em>{{previousArea}}</em>',
      info_move_2: 'new data under <em>{{newArea}}</em>',
      info_move_replace_intro: 'For <code>{{monitorSerialNum}}</code> monitor:',
      info_move_replace_1: 'previous data under <em>{{previousArea}}</em>',
      info_move_replace_2: 'new data under <em>{{newArea}}</em>',
      warn_title_one: 'Monitor must be powered off before clicking OK',
      warn_title_other: 'Monitors must be powered off before clicking OK',
      warn_intro_one: 'Steps to move <code>{{monitorSerialNum}}</code> monitor:',
      warn_intro_other:
        'Steps to move <code>{{monitorSerialNum}}</code> and <code>{{replaceMonitorSerialNum}}</code> monitors',
      warn_power_off_one: 'Power off the monitor:',
      warn_power_off_other: 'Power off the monitors:',
      warn_unplug: 'Unplug the USB cable',
      warn_remove_batteries: 'Remove the batteries',
      warn_change_settings: 'Change monitor areas with this form and click OK',
      warn_move_one: 'Move the monitor',
      warn_move_other: 'Move the monitors',
      warn_power_on_one: 'Power on the monitor',
      warn_power_on_other: 'Power on the monitors',
      warn_risk: 'Otherwise monitor data might be sent to the wrong area.',
      /////////////////////////
      // Area settings
      /////////////////////////
      set_settings_success: 'Settings were updated successfully.',
      // Field errors
      threshold_more_than_next: 'Threshold should be less than the next threshold value {{value}}',
      threshold_less_than_previous: 'Threshold should be more than the previous threshold value {{value}}',
      range_time_undefined_error: 'Other time of period must be filled',
      range_time_order_error: 'This time should be before the next one',
      data_freq_more_than_data_sync: 'Measurement interval should be less or equal to sending interval',
      data_sync_less_than_data_freq: 'Sending interval should be greater or equal to measurement interval',
      // Modal title
      area_settings_title: '⚙ Settings for <em>{{area}}</em>',
      // Labels
      color_h2_label: 'Monitor colors from CO2 ppm level',
      threshold_label: 'Until',
      color1_label: 'Good',
      color2_label: 'Moderate',
      color3_label: 'Slightly unhealthy',
      color4_label: 'Unhealthy',
      energy_savings_h2_label: 'Power & data savings',
      data_freq_label: 'Measurement interval',
      data_freq_description: '',
      data_freq_option_auto_label: 'Auto',
      data_freq_option_auto_description: '10 minutes using batteries, 3 minutes using USB',
      data_freq_option_0_5_label: '30 seconds',
      data_freq_option_label_one: '1 minute',
      data_freq_option_label_other: '{{count, number}} minutes',
      data_sync_freq_label: 'Sending interval',
      data_sync_freq_description: 'In absence of CO2 event, or if cellular network is weak when battery powered',
      data_sync_freq_option_auto_label: 'Auto',
      data_sync_freq_option_auto_description: '6 hours using batteries, 10 minutes using USB',
      data_sync_freq_option_label_one: '1 minute',
      data_sync_freq_option_label_other: '{{count, number}} minutes',
      data_sync_freq_option_360_label: '6 hours',
      data_sync_freq_option_1440_label: '24 hours',
      co2_min_sync_label: 'CO2 event threshold',
      co2_min_sync_description: 'Minimum CO2 concentration that forces measurement sending',
      co2_min_sync_option_auto_label: 'Auto',
      co2_min_sync_option_auto_description: '1500 ppm using batteries, 800 ppm using USB',
      co2_min_sync_option_1_label: '0 ppm',
      co2_min_sync_option_1_description: 'Each measurement is sent every {{dataFreq}} - No power & data savings',
      co2_min_sync_option_9999_label: 'Disabled',
      co2_min_sync_option_9999_description: 'Each measurement is sent every {{dataSyncFreq}}',
      co2_min_sync_option_label_other: '{{count, number}} ppm',
      setup_sync_freq_label: 'Settings update interval',
      setup_sync_freq_description: '',
      setup_sync_freq_option_auto_label: 'Auto',
      setup_sync_freq_option_auto_description: '24 hours using batteries, 1 hour using USB',
      setup_sync_freq_option_label_one: '1 hour',
      setup_sync_freq_option_label_other: '{{count, number}} hours',
      reboot_freq_label: 'Reboot interval',
      reboot_freq_description: '',
      reboot_freq_option_auto_label: 'Auto',
      reboot_freq_option_auto_description: 'No reboot',
      reboot_freq_option_label_one: '1 day',
      reboot_freq_option_label_other: '{{count, number}} days',
      coord_h2_label: 'Monitor location',
      set_location_label: 'Set from my location',
      set_location_tooltip: 'Set my current smartphone or PC location as the monitor position',
      set_location_error: 'Impossible to use current location (Details: {{error}}).',
      set_location_accuracy_error:
        'The location of your browser within {{accuracy}} meters is not precise enough. Please use a smartphone with location high accuracy mode turned on, or enter the coordinates manually.',
      longitude_label: 'Longitude',
      latitude_label: 'Latitude',
      altitude_label: 'Altitude',
      coord_placeholder: 'optional',
      opening_h2_label: 'Opening hours',
      Mon1_label: 'Monday',
      Tue1_label: 'Tuesday',
      Wed1_label: 'Wednesday',
      Thu1_label: 'Thursday',
      Fri1_label: 'Friday',
      Sat1_label: 'Saturday',
      Sun1_label: 'Sunday',
      timeZone_label: 'Time zone',
      holidaysCountry_placeholder: 'optional',
      holidaysCountry_label: 'Holidays country',
      // DCV
      min_validation: 'Value must be more than ',
      max_validation: 'Value must be less than ',
      dUPresence_validation: 'Minimum voltage for human presence must be between minimum and maximum voltage',
      dcv_h2_label: 'Demand Control Ventilation',
      s1_label: 'CO2 Output',
      s1_tooltip: '',
      s1_n_label: 'None',
      s1_n_description: '',
      s1_v_label: 'VAV',
      s1_v_description: 'PI model signal sent to VAV based on area occupation',
      s1_b_label: 'BMS',
      s1_b_description: 'Normalised voltage sent to BMS based on the measured CO2',
      s1_field_set: 'CO2 parameters',
      s1_v_field_set_tooltip:
        'Output sent to the VAV using meo’s air equality monitor’s <1>PI controller with deadband and anti-windup</1> or to BMS based on measured CO2',
      s1_b_field_set_tooltip: '',
      co2Set_label: 'CO2 setpoint (in ppm)',
      co2Set_tooltip:
        'Calculated by substracting the deadband (see below <i>Advanced parameters</i>) from the allowed highest CO2 value.',
      co2Presence_label: 'Occupancy CO2 threshold (in ppm)',
      co2Presence_tooltip: 'Occupancy detection when measured CO2 is greater than the Occupancy CO2 threshold.',
      dUPresence_label: 'Minimum voltage for occupancy (in V)',
      dUPresence_tooltip: 'PI model minimum voltage when measured CO2 is greater than the Occupancy CO2 threshold.',
      v_dUMinCo2_label: 'Minimum VAV voltage (in V)',
      v_dUMinCo2_tooltip: 'Lowest voltage expected by the VAV.',
      v_dUMaxCo2_label: 'Maximum VAV voltage (in V)',
      v_dUMaxCo2_tooltip: 'Highest voltage expected by the VAV.',
      s1_collapse: 'Advanced parameters',
      s1_collapse_reset: 'Back to Defaults',
      kp_label: 'Proportional gain Kp (in mV/ppm)',
      kp_tooltip: 'Kp parameter of the PI controller.',
      ti_label: 'Integral time Ti (in min)',
      ti_tooltip: 'Ti parameter of the PI controller.',
      db_label: 'Deadband db (in ppm)',
      db_tooltip:
        'Range of values around the CO2 setpoint where no control adjustments are made by the PI controller. Prevents unnecessary corrections for minor fluctuations, enhancing system stability and longevity.',
      co2Min_label: 'Lowest CO2 of the band (in ppm)',
      co2Min_tooltip: 'CO2 value linked to Lowest voltage.',
      co2Max_label: 'Highest CO2 of the band (in ppm)',
      co2Max_tooltip: 'CO2 value linked to Highest voltage.',
      b_dUMinCo2_label: 'Lowest voltage (in V)',
      b_dUMinCo2_tooltip: 'Lowest voltage expected by the BMS for the Lowest CO2 of the band.',
      b_dUMaxCo2_label: 'Highest voltage (in V)',
      b_dUMaxCo2_tooltip: 'Highest voltage expected by the BMS for the Highest CO2 of the band.',
      s2_label: 'S2 output',
      s2_tooltip: '2nd 0-10V model output as set below for the selected.',
      s2_n_label: 'None',
      s2_n_description: '',
      s2_t_label: 'Temperature',
      s2_t_description: '',
      s2_field_set: 'S2 parameters',
      tMin_label: 'Lowest temperature (in °C)',
      tMin_tooltip: 'Lowest value of the temperature band linked to Lowest voltage.',
      tMax_label: 'Highest temperature (in °C)',
      tMax_tooltip: 'Highest value of the temperature band linked to Highest voltage.',
      dUMinS2_label: 'Lowest voltage (in V)',
      dUMinS2_tooltip: 'Lowest voltage linked to Lowest temperature.',
      dUMaxS2_label: 'Highest voltage (in V)',
      dUMaxS2_tooltip: 'Highest voltage linked to Highest temperature.',
      s3_label: 'S3 output',
      s3_tooltip: '3rd 0-10V model output as set below for the selected.',
      s3_n_label: 'None',
      s3_n_description: '',
      s3_h_label: 'Humidity',
      s3_h_description: '',
      s3_field_set: 'S3 Parameters',
      rhMin_label: 'Lowest humidity (in %)',
      rhMin_tooltip: 'Lowest value of the Humidity band, linked to Lowest voltage.',
      rhMax_label: 'Highest humidity (in %)',
      rhMax_tooltip: 'Highest value of the Humidity band, linked to Highest voltage.',
      dUMinS3_label: 'Lowest voltage (in V)',
      dUMinS3_tooltip: 'Lowest voltage linked to Lowest humidity.',
      dUMaxS3_label: 'Highest voltage (in V)',
      dUMaxS3_tooltip: 'Highest voltage linked to Highest humidity.',
      sIn_label: 'Signal input',
      sIn_tooltip: 'Limited to 10V.',
      sIn_y_label: 'Yes',
      sIn_y_description: '',
      sIn_n_label: 'No',
      sIn_n_description: '',
      // warning
      area_settings_warning_title: 'Modifications effective time',
      area_settings_warning_content:
        '<p>Your modifications might reach this monitor <strong>in a few hours</strong>,' +
        ' based on <em>Monitor settings update frequency</em> currently stored on the monitor, and the synchronisation last time.</p>' +
        '<p>You can apply the settings <strong>immediately by powering the monitor off and on again</strong>, after you click OK.</p>',
      ////////////////////
      // Modal Buttons
      ok: 'OK',
      submitted: 'Updating...',
      cancel: 'Cancel',
      ////////////////////
      // countries
      AF: 'Afghanistan',
      AD: 'Andorra',
      AR: 'Argentina',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      BY: 'Belarus',
      BE: 'Belgium',
      BR: 'Brazil',
      BG: 'Bulgaria',
      CA: 'Canada',
      CL: 'Chile',
      CO: 'Colombia',
      HR: 'Croatia',
      CZ: 'Czechia',
      DK: 'Denmark',
      DO: 'Dominican Republic',
      EG: 'Egypt',
      EE: 'Estonia',
      FI: 'Finland',
      FR: 'France',
      DE: 'Germany',
      GR: 'Greece',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      IE: 'Ireland',
      IL: 'Israel',
      IT: 'Italy',
      JP: 'Japan',
      KE: 'Kenya',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MX: 'Mexico',
      NL: 'Netherlands',
      NZ: 'NewZealand',
      NI: 'Nicaragua',
      NG: 'Nigeria',
      NO: 'Norway',
      PY: 'Paraguay',
      PE: 'Peru',
      PL: 'Poland',
      PT: 'Portugal',
      RU: 'Russia',
      RS: 'Serbia',
      SG: 'Singapore',
      SK: 'Slovakia',
      SI: 'Slovenia',
      ZA: 'South Africa',
      ES: 'Spain',
      SE: 'Sweden',
      CH: 'Switzerland',
      UA: 'Ukraine',
      UK: 'United Kingdom',
      US: 'United States',
    },
  },
  fr: {
    translation: {
      // Warnings:
      // 1. HTML tags cannot be used in all values, but mainly only in descriptions
      // 2. only simple tags without any attribute are allowed (see transKeepBasicHtmlNodesFor above)

      // for all select dropdowns
      select_create: 'Créer : {{input}}',
      ////////////////////////////////
      // Panel
      ////////////////////////////////
      monitor_settings_success: 'Le capteur est affecté à {{area}}',
      rename_success: 'La zone a été renommée {{updatedName}}',
      add_monitor_button: 'Activer un capteur meo',
      toggle_colors: 'Couleurs',
      toggle_energy_saving: 'Économie d’énergie et de données',
      toggle_location: 'Position',
      toggle_opening_hours: 'Heures d’ouverture',
      toggle_dcv: 'Contrôle de Ventilation',
      loading: 'Chargement en cours...',
      /////////////////////////////////
      // Add a monitor and Change area
      /////////////////////////////////
      // button
      scanner_card_start: 'Prenez la carte d’activation dans la boite du capteur et :',
      scanner_card_scan: 'Scannez le QR code ci-dessous, ou',
      scanner_card_fallback: 'Saisissez le code d’activation',
      // errors
      scan_invalid_qr_code: 'Le QR code n’est pas valide, le code d’activation doit être recopié manuellement.',
      scan_permission_error:
        'Autorisation de la caméra refusée, veuillez recommencer après avoir accepté l’autorisation ou alors saisir le code d’activation ci-dessous.',
      scan_error:
        'Le scanner ne peut pas être utilisé, le code d’activation doit être recopié manuellement (Détails de l’erreur : {{error}}).',
      cannot_be_blank: 'Ne peut être vide',
      cannot_be_same_area: 'Ne peut pas être identique à la zone actuelle « {{oldArea}} »',
      eight_chars_token:
        'Le code d’activation {{trimmed}} doit comporter 12 caractères alphanumériques. À ne pas confondre avec le numéro de série.',
      bad_token_length: 'Le code d’activation {{trimmed}} doit comporter 12 caractères alphanumériques.',
      required_rename_move: 'Choisir « Renommer la zone » ou « Déplacer le capteur »',
      // modal title
      change_area_modal_title_new: 'Activer un capteur',
      change_area_modal_title_update: '✎ Changer la zone <em>{{area}}</em>',
      // fields
      token_label: 'Code d’activation du capteur',
      token_description:
        'Les 12 caractères alphanumériques du code d’activation sont sous le QR code de la carte d’activation.',
      token_placeholder: '1234 5678 9AbC',
      rename_move_label: 'Objet',
      rename_move_description_intro: 'Choisir une des 2 options suivantes :',
      rename_move_description_rename_intro: ' est choisi dans l’un des cas suivants :',
      rename_move_description_rename_1:
        'faute de frappe : par ex. <em>Sale 101</em> a été saisi au lieu de <em>Salle 101</em>',
      rename_move_description_rename_2:
        'changement de nom de la zone du capteur : par ex. <em>Salle 101</em> devient <em>Salle polyvalente</em>',
      rename_move_description_move: ' est utilisé dans le cas de déplacement du capteur vers une nouvelle zone.',
      rename_label: 'Renommer la zone',
      move_label: 'Déplacer le capteur',
      rename_area_label: 'Zone mise à jour',
      rename_area_description: 'La zone actuelle est <em>{{area}}</em>',
      area_label: 'Zone',
      area_description: 'La zone est l’emplacement où est situé le capteur, tel que <em>Salle 104</em>.',
      area_placeholder_one: 'Saisir une nouvelle zone',
      area_placeholder_other: 'Saisir une nouvelle zone ou choisir une zone existante',
      replace_area_label: 'Zone du capteur remplacé',
      replace_area_description_one:
        ' Une nouvelle zone pour le capteur remplacé <code>{{monitorSerialNum}}</code>, actuellement dans <em>{{area}}</em>.' +
        ' Une zone est généralement un nom de pièce, ou par défaut <em>Zone inconnue pour {{monitorSerialNum}}</em>.',
      replace_area_description_other:
        ' Une nouvelle zone ou une zone existante sans capteur, pour le capteur remplacé <code>{{monitorSerialNum}}</code>, actuellement dans <em>{{area}}</em>.' +
        ' Une zone est généralement un nom de pièce ou par défaut <em>Zone inconnue pour {{monitorSerialNum}}</em>.',
      replace_area_placeholder_one: 'Saisir une nouvelle zone',
      replace_area_placeholder_other: 'Saisir une nouvelle zone ou choisir une zone existante',
      replace_area_default: 'Zone inconnue pour {{monitorSerialNum}}',
      // Demand Control
      dc_abbr: 'VCD',
      dc_abbr_title: 'Ventilation Contrôlée à la Demande',
      dc_name_prefix: 'Nom de la\u00A0',
      dc_name_suffix: '',
      dc_id_prefix: 'Identification de la\u00A0',
      dc_id_suffix: '',
      // boxes
      info_title: 'Description des graphes après avoir validé avec le bouton OK',
      info_intro: 'Pour le capteur <code>{{monitorSerialNum}}</code> :',
      info_rename_1: 'présenteront les données précédentes et les nouvelles avec l’intitulé <em>{{updatedName}}</em>',
      info_move_1: 'les données précédentes avec l’intitulé <em>{{previousArea}}</em>',
      info_move_2: 'les nouvelles données avec l’intitulé <em>{{newArea}}</em>',
      info_move_replace_intro: 'Pour le capteur <code>{{monitorSerialNum}}</code> :',
      info_move_replace_1: 'les données précédentes seront visibles avec l’intitulé <em>{{previousArea}}</em>',
      info_move_replace_2: 'les nouvelles données seront visbles avec l’intitulé <em>{{newArea}}</em>',
      warn_title_one: 'Le capteur doit être éteint avant de cliquer sur le bouton OK',
      warn_title_other: 'Le capteur doit être éteint avant de cliquer sur le bouton OK',
      warn_intro_one: 'Étapes pour déplacer le capteur <code>{{monitorSerialNum}}</code> :',
      warn_intro_other:
        'Étapes pour déplacer les capteurs <code>{{monitorSerialNum}}</code> et <code>{{replaceMonitorSerialNum}}</code>',
      warn_power_off_one: 'Éteindre le capteur',
      warn_power_off_other: 'Éteindre les capteurs',
      warn_unplug: 'Débrancher le cable USB',
      warn_remove_batteries: 'Retirer les piles',
      warn_change_settings: 'Indiquer la nouvelle zone du capteur dans ce formulaire et valider avec le bouton OK',
      warn_move_one: 'Déplacer le capteur',
      warn_move_other: 'Déplacer les capteurs',
      warn_power_on_one: 'Remettre le capteur sous tension',
      warn_power_on_other: 'Remettre les capteurs sous tension',
      warn_risk: 'Sinon, les données du capteur pourraient ne pas être liées à la bonne zone.',
      ///////////////////
      // Area settings
      ///////////////////
      set_settings_success: 'Les paramètres ont été mis à jour avec succès.',
      // Field errors
      threshold_more_than_next: 'Le seuil doit être inférieur à {{value}}, le seuil suivant',
      threshold_less_than_previous: 'Le seuil doit être supérieur à {{value}}, le seuil précédent',
      range_time_undefined_error: 'L’autre heure de la période doit être remplie',
      range_time_order_error: 'Cette heure doit être avant la suivante',
      data_freq_more_than_data_sync:
        'L’intervalle de mesure doit être inférieur ou égal à l’intervalle d’envoi des mesures',
      data_sync_less_than_data_freq:
        'L’intervalle d’envoi des mesures doit être supérieur ou égal à l’intervalle de mesures',
      // Modal title
      area_settings_title: 'Paramètres pour {{area}}',
      // Labels
      color_h2_label: 'Couleurs du capteur en fonction du niveau de CO2 en ppm',
      threshold_label: 'Jusqu’à',
      color1_label: 'Bon',
      color2_label: 'Modéré',
      color3_label: 'Mauvais pour certaines personnes',
      color4_label: 'Mauvais',
      energy_savings_h2_label: 'Économie d’énergie et de données',
      data_freq_label: 'Intervalle de mesure',
      data_freq_description: '',
      data_freq_option_auto_label: 'Auto',
      data_freq_option_auto_description: '10 minutes avec des piles, 3 minutes avec un cable USB',
      data_freq_option_0_5_label: '30 secondes',
      data_freq_option_label_one: '1 minute',
      data_freq_option_label_other: '{{count, number}} minutes',
      data_sync_freq_label: 'Intervalle d’envoi des mesures',
      data_sync_freq_description:
        'En absence d’événement de pollution au CO2, ou lorsque le signal radio est faible avec piles',
      data_sync_freq_option_auto_label: 'Auto',
      data_sync_freq_option_auto_description: '6 heures avec des piles, 10 minutes avec un cable USB',
      data_sync_freq_option_label_one: '1 minute',
      data_sync_freq_option_label_other: '{{count, number}} minutes',
      data_sync_freq_option_360_label: '6 heures',
      data_sync_freq_option_1440_label: '24 heures',
      co2_min_sync_label: 'Seuil d’événement CO2',
      co2_min_sync_description: 'Concentration minimale de CO2 qui force l’envoi de mesures',
      co2_min_sync_option_auto_label: 'Auto',
      co2_min_sync_option_auto_description: '1500 ppm avec des piles, 800 ppm avec un cable USB',
      co2_min_sync_option_1_label: '0 ppm',
      co2_min_sync_option_1_description:
        'Chaque mesure est envoyée toutes les {{dataFreq}} - Pas d’économie d’énergie et de données',
      co2_min_sync_option_9999_label: 'Désactivé',
      co2_min_sync_option_9999_description: 'Chaque mesure est envoyée toutes les {{dataSyncFreq}}',
      co2_min_sync_option_label_other: '{{count, number}} ppm',
      setup_sync_freq_label: 'Intervalle de mise à jour des paramètres du capteur',
      setup_sync_freq_description: '',
      setup_sync_freq_option_auto_label: 'Auto',
      setup_sync_freq_option_auto_description: '24 heures avec des piles, 1 heure avec un cable USB',
      setup_sync_freq_option_label_one: '1 heure',
      setup_sync_freq_option_label_other: '{{count, number}} heures',
      reboot_freq_label: 'Intervalle de redémarrage du capteur',
      reboot_freq_description: '',
      reboot_freq_option_auto_label: 'Auto',
      reboot_freq_option_auto_description: 'Pas de redémarrage',
      reboot_freq_option_label_one: '1 jour',
      reboot_freq_option_label_other: '{{count, number}} jours',
      coord_h2_label: 'Position',
      set_location_label: 'Utiliser ma position actuelle',
      set_location_tooltip: 'Définir l’emplacement actuel de mon smartphone ou de mon PC comme position du capteur',
      set_location_error: 'Impossible d’utiliser la position actuelle (Détails de l’erreur : {{error}}).',
      set_location_accuracy_error:
        'La localisation de votre navigateur à {{accuracy}} mètres près n’est pas assez précise. Veuillez utiliser un smartphone avec le mode de localisation haute précision activé, ou entrez les coordonnées manuellement.',
      longitude_label: 'Longitude',
      latitude_label: 'Latitude',
      altitude_label: 'Altitude',
      coord_placeholder: 'optionnel',
      opening_h2_label: 'Heures d’ouverture',
      Mon1_label: 'Lundi',
      Tue1_label: 'Mardi',
      Wed1_label: 'Mercredi',
      Thu1_label: 'Jeudi',
      Fri1_label: 'Vendredi',
      Sat1_label: 'Samedi',
      Sun1_label: 'Dimanche',
      timeZone_label: 'Fuseau horaire',
      holidaysCountry_placeholder: 'optionnel',
      holidaysCountry_label: 'Congés',
      // DCV
      min_validation: 'Le nombre doit être supérieur à ',
      max_validation: 'Le nombre doit être inférieur à ',
      dUPresence_validation: 'La tension minimale en cas de présence doit être entre les tensions minimale et maximale',
      dcv_h2_label: 'Ventilation contrôlée',
      s1_label: 'Sortie CO2',
      s1_tooltip: '',
      s1_n_label: 'Aucune',
      s1_n_description: '',
      s1_v_label: 'VAV',
      s1_v_description:
        'Pilotage de la VAV par le contrôleur PI du moniteur pour ajuster la ventilation au taux d’occupation des espaces contrôlés.',
      s1_b_label: 'GTB',
      s1_b_description: 'Conversion de la valeur de CO2 mesurée en un signal normalisé en tension pour la GTB.',
      s1_field_set: 'Paramètres CO2',
      s1_v_field_set_tooltip:
        'Commande pour la VAV utilisant le <1>contrôleur PI avec plage neutre et anti-windup</1> du moniteur meo ou pour la GTB utilisant la valeur de CO2 mesurée.',
      s1_b_field_set_tooltip: '',
      co2Set_label: 'Consigne CO2 (en ppm)',
      co2Set_tooltip:
        'Valeur obtenue en retranchant la valeur de plage neutre (voir <i>Paramètres avancés</i> ci-dessous)  de la valeur de CO2 à ne pas dépasser.',
      co2Presence_label: 'Seuil CO2 de détection de présence (en ppm)',
      co2Presence_tooltip: 'Valeur minimale de CO2 en cas de présence humaine.',
      dUPresence_label: 'Tension minimale de VAV en cas de présence humaine (en V)',
      dUPresence_tooltip:
        'Commande minimale du contrôleur PI quand le taux de CO2 dépasse le seuil CO2 de détection de présence.',
      v_dUMinCo2_label: 'Tension minimale de VAV (en V)',
      v_dUMinCo2_tooltip: 'Commande minimale attendue par la VAV.',
      v_dUMaxCo2_label: 'Tension maximale de VAV (en V)',
      v_dUMaxCo2_tooltip: 'Commande maximale attendue par la VAV.',
      s1_collapse: 'Paramètres avancés',
      s1_collapse_reset: 'Réinitialiser',
      kp_label: 'Gain proportionnel Kp (en mV/ppm)',
      kp_tooltip: 'Paramètre Kp du contrôleur PI.',
      ti_label: 'Temps intégral (en min)',
      ti_tooltip: 'Paramètre Ti du contrôleur PI.',
      db_label: 'Plage neutre db (en ppm)',
      db_tooltip:
        'Plage de valeurs autour de la consigne CO2 où la commande du contrôleur PI n’est pas ajustée. L’absence d’ajustements fréquents pour de petites variations de CO2 favorise une stabilité et une durabilité accrues du système.',
      co2Min_label: 'CO2 minimum (en ppm)',
      co2Min_tooltip: 'Valeur minimale de CO2 associée à la tension minimale.',
      co2Max_label: 'CO2 maximum (en ppm)',
      co2Max_tooltip: 'Valeur maximale de CO2 associée à la tension maximale.',
      b_dUMinCo2_label: 'Tension minimale (en V)',
      b_dUMinCo2_tooltip: 'Tension associée au CO2 minimal défini.',
      b_dUMaxCo2_label: 'Tension maximale (en V)',
      b_dUMaxCo2_tooltip: 'Tension associée au CO2 maximal défini.',
      s2_label: 'Sortie S2',
      s2_tooltip: '2e sortie de type 0-10V telles que configurée ci-dessous pour le paramètre choisi.',
      s2_n_label: 'Aucune',
      s2_n_description: '',
      s2_t_label: 'Température',
      s2_t_description: '',
      s2_field_set: 'Paramètres S2',
      tMin_label: 'Température minimale (en °C)',
      tMin_tooltip: 'Valeur minimale de l’intervalle de température associée à la tension minimale.',
      tMax_label: 'Température maximale (en °C)',
      tMax_tooltip: 'Valeur maximale de l’intervalle de température associée à la tension maximale.',
      dUMinS2_label: 'Tension minimale (en V)',
      dUMinS2_tooltip: 'Tension associée à la température minimale.',
      dUMaxS2_label: 'Tension maximale (en V)',
      dUMaxS2_tooltip: 'Tension associée à la température maximale.',
      s3_label: 'Sortie S3',
      s3_tooltip: '3e sortie de type 0-10V telles que configurée ci-dessous pour le paramètre choisi.',
      s3_n_label: 'Aucune',
      s3_n_description: '',
      s3_h_label: 'Humidité',
      s3_h_description: '',
      s3_field_set: 'Paramètres S3',
      rhMin_label: 'Humidité minimale (en %)',
      rhMin_tooltip: 'Valeur minimale de l’intervalle d’humidité associée à la tension minimale.',
      rhMax_label: 'Humidité maximale (en %)',
      rhMax_tooltip: 'Valeur maximale de l’intervalle d’humidité associée à la tension maximale.',
      dUMinS3_label: 'Tension minimale (en V)',
      dUMinS3_tooltip: 'Tension associée à l’humidité minimale.',
      dUMaxS3_label: 'Tension maximale (en V)',
      dUMaxS3_tooltip: 'Tension associée à l’humidité maximale.',
      sIn_label: 'Signal d’entrée',
      sIn_tooltip: 'Limitée à 10V.',
      sIn_y_label: 'Oui',
      sIn_y_description: '',
      sIn_n_label: 'Non',
      sIn_n_description: '',
      // warning
      area_settings_warning_title: 'Temps d’application des modifications',
      area_settings_warning_content:
        '<p>Vos modifications pourraient être appliquées par le capteur <strong>dans plusieurs heures</strong>,' +
        ' en fonction de l’<em>Intervalle de mise à jour des paramètres du capteur</em> actuellement stocké sur le capteur et de la dernière synchronisation.</p>' +
        '<p>Vous pouvez appliquer les paramètres <strong>immédiatement en éteignant puis en rallumant le capteur</strong>, après avoir cliqué sur OK.</p>',
      ////////////////////
      // Modal Buttons
      ok: 'OK',
      submitted: 'En cours...',
      cancel: 'Annuler',
      ////////////////////
      // countries
      AF: 'Afghanistan',
      AD: 'Andorre',
      AR: 'Argentine',
      AW: 'Aruba',
      AU: 'Australie',
      AT: 'Autriche',
      BY: 'Biélorussie',
      BE: 'Belgique',
      BR: 'Brésil',
      BG: 'Bulgarie',
      CA: 'Canada',
      CL: 'Chili',
      CO: 'Colombie',
      HR: 'Croatie',
      CZ: 'Tchéquie',
      DK: 'Danemark',
      DO: 'République dominicaine',
      EG: 'Égypte',
      EE: 'Estonie',
      FI: 'Finlande',
      FR: 'France',
      DE: 'Allemagne',
      GR: 'Grèce',
      HN: 'Honduras',
      HK: 'Hong Kong ',
      HU: 'Hongrie',
      IS: 'Islande',
      IN: 'Inde',
      IE: 'Irlande',
      IL: 'Israël',
      IT: 'Italie',
      JP: 'Japon',
      KE: 'Kenya',
      LT: 'Lituanie',
      LU: 'Luxembourg',
      MX: 'Mexique',
      NL: 'Pays-Bas',
      NZ: 'Nouvelle-Zélande',
      NI: 'Nicaragua',
      NG: 'Nigéria',
      NO: 'Norvège',
      PY: 'Paraguay',
      PE: 'Pérou',
      PL: 'Pologne',
      PT: 'Portugal',
      RU: 'Russie',
      RS: 'Serbie',
      SG: 'Singapour',
      SK: 'Slovaquie',
      SI: 'Slovénie',
      ZA: 'Afrique du Sud',
      ES: 'Espagne',
      SE: 'Suède',
      CH: 'Suisse',
      UA: 'Ukraine',
      UK: 'Royaume-Uni',
      US: 'États-Unis',
    },
  },
};

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    transKeepBasicHtmlNodesFor: [
      'b',
      'br',
      'cite',
      'code',
      'em',
      'i',
      'mark',
      'q',
      'samp',
      'small',
      'strong',
      'sub',
      'sup',
      'u',
      'var',
      'p',
    ],
  },
  resources: resources,
});

export default i18n;
