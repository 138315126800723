import { css } from '@emotion/css';
import { DataFrame, Field, FieldMatcherID, getTimeZoneInfo, PanelData, PanelProps, Vector } from '@grafana/data';
import { config, getBackendSrv, getDataSourceSrv, locationService } from '@grafana/runtime';
import {
  Alert,
  AlertVariant,
  Button,
  HorizontalGroup,
  InlineSwitch,
  ModalsController,
  Table,
  TableSortByFieldState,
  useStyles2,
  VerticalGroup,
} from '@grafana/ui';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { from } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { Options } from 'types';
import {
  AreaSettingsModal,
  auto,
  defaultCountryFromTimeZone,
  defaultOpenHour,
  defaultTimeZone,
  FORM_TO_DB_FACTOR,
  OpenHour,
  SETTINGS_DEFAULT,
  SettingsDTO,
} from './components/AreaSettingsModal';
import { ChangeAreaDTO, ChangeAreaModal } from './components/ChangeAreaModal';
import { geojson } from './components/Coordinates';
import { toTestingStatus } from './TestingStatus';
interface Props extends PanelProps<Options> {}
interface AlertAttributes {
  title: string;
  severity: AlertVariant;
}

const currentFrameIndex = (data: PanelData, frameIndex: number) =>
  frameIndex > 0 && frameIndex < data.series?.length ? frameIndex : 0;

const setHiddenColumns = (
  frame: DataFrame,
  condition: (field: Field<any, Vector<any>>) => boolean,
  isHidden: (field: Field<any, Vector<any>>) => boolean
): void =>
  frame.fields?.filter(condition).forEach((field) => {
    field.config.custom = field.config.custom || {};
    field.config.custom.hidden = isHidden(field);
  });

const isToggleField = (field: Field<any, Vector<any>>) => toggleKeys.some((k) => field.name.startsWith(k + '.'));

const filterColumns = (toggles: Set<Toggle>, data: PanelData, frameIndex: number): DataFrame => {
  const frame: DataFrame = { ...data.series[currentFrameIndex(data, frameIndex)] }; // shallow clone
  // filter columns to avoid showing default random walk data at org creation
  setHiddenColumns(
    frame,
    (field) =>
      !isToggleField(field) &&
      !['Serial number', 'Area', 'Calibration', 'edit', 'monitorUuid', 'thingUuid'].includes(field.name),
    () => true
  );
  toggleKeys.forEach((k) =>
    setHiddenColumns(
      frame,
      (field) => field.name.startsWith(k + '.'),
      () => !toggles.has(k)
    )
  );
  // only translated holidays country is shown
  setHiddenColumns(
    frame,
    (field) => field.name === 'opening_hours.holidaysCountry',
    () => true
  );
  return frame;
};

const undefinedOrValid = (val: any) => (val === null ? undefined : val);

const convertSettings = (key: string, val: any) =>
  key === 'openingHours' ? convertOpeningHours(val) : { [key]: undefinedOrValid(val) };

type frenchDayOfWeek = 'Lun' | 'Mar' | 'Mer' | 'Jeu' | 'Ven' | 'Sam' | 'Dim';

const frenchDayOfWeeks = {
  Lun: 'Mon',
  Mar: 'Tue',
  Mer: 'Wed',
  Jeu: 'Thu',
  Ven: 'Fri',
  Sam: 'Sat',
  Dim: 'Sun',
};

// Sample Lun: 10:00-12:00, 14:00-18:00; Mar: 09:00-12:00
const convertOpeningHours = (val: string | undefined | null): { [key in OpenHour]: string | null } => {
  const settings = {} as { [key in OpenHour]: string | null };
  if (val !== undefined && val !== null) {
    Object.values(frenchDayOfWeeks).forEach((dow) =>
      [1, 2, 3, 4].forEach((i) => {
        settings[(dow + i) as OpenHour] = null;
      })
    );
    if (val !== '') {
      val.split('; ').forEach((dayOfWeekRanges) => {
        const dowAndRanges = dayOfWeekRanges.split(': ');
        const dow = frenchDayOfWeeks[dowAndRanges[0] as frenchDayOfWeek] ?? dowAndRanges[0];
        dowAndRanges[1]
          .split(', ')
          .map((r) => r.split('-'))
          .flat()
          .forEach((t, i) => {
            settings[(dow + (i + 1)) as OpenHour] = t;
          });
      });
    }
  }
  return settings;
};

const findThingDetails = (
  thingUuid: string,
  data: PanelData,
  frameIndex: number
): { settings: SettingsDTO; area: string } => {
  const frame = data.series[currentFrameIndex(data, frameIndex)];
  const thingRowIdx = frame.fields
    .find((field) => field.name === 'thingUuid')
    ?.values.toArray()
    .findIndex((v) => v === thingUuid);
  if (thingRowIdx === undefined || thingRowIdx === -1) {
    return { settings: {}, area: '' };
  }
  return {
    settings: frame.fields
      .filter((f) => isToggleField(f) && f.name !== 'edit')
      .reduce(
        (obj, cur) => ({
          ...obj,
          ...convertSettings(cur.name.substring(cur.name.indexOf('.') + 1), cur.values.get(thingRowIdx)),
        }),
        {}
      ),
    area: frame.fields.find((f) => f.name === 'Area')?.values.get(thingRowIdx),
  };
};

const toggleKeys = ['colors', 'energy_saving', 'location', 'opening_hours', 'dcv'] as const;
type Toggle = (typeof toggleKeys)[number];

export const SettingsPanel: React.FC<Props> = ({
  data,
  options,
  width,
  height,
  fieldConfig,
  onFieldConfigChange,
  onOptionsChange,
  timeZone,
}: Props) => {
  const [alertAttributes, setAlertAttributes] = useState<AlertAttributes | undefined>(undefined);
  const [toggles, setToggles] = useState(new Set<Toggle>());

  const [filteredData, setFilteredData] = useState(filterColumns(toggles, data, options.frameIndex));
  const [areaModalShown, setAreaModalShown] = useState(false);
  const [thingModalShown, setThingModalShown] = useState(false);
  const [urlVariables, setUrlVariables] = useState({
    token: locationService.getSearch()?.get('var-monitorToken'),
    monitorUuid: locationService.getSearch()?.get('var-monitorUuid'),
    thingUuid: locationService.getSearch()?.get('var-thingUuid'),
  });
  const history = locationService.getHistory();

  useEffect(() => {
    return history.listen((location: Location) => {
      const searchParams = new URLSearchParams(location.search);
      setUrlVariables({
        token: searchParams.get('var-monitorToken') || '',
        monitorUuid: searchParams.get('var-monitorUuid') || '',
        thingUuid: searchParams.get('var-thingUuid') || '',
      });
    });
  }, [history]);

  const { t, i18n } = useTranslation();
  const styles = useStyles2(getStyles);

  useEffect(() => {
    if (i18n.language !== options.language) {
      i18n.changeLanguage(options.language);
    }
  }, [options.language, i18n]);

  useEffect(() => {
    setFilteredData(filterColumns(toggles, data, options.frameIndex));
  }, [data, toggles, options.frameIndex]);

  const query = (rawSql: string, successMessage: string, setErrorOrHideModal: (err: string) => void) =>
    from(getDataSourceSrv().get(null))
      .pipe(take(1))
      .pipe(
        mergeMap((ds) =>
          getBackendSrv().fetch({
            method: 'POST',
            url: '/api/ds/query',
            data: {
              queries: [
                {
                  datasourceId: ds.id,
                  refId: '1',
                  // user can write arbitrary SQL in any panel, so no need to care about SQL injection
                  rawSql: rawSql,
                  format: 'table',
                },
              ],
            },
          })
        ),
        catchError((err) => {
          throw new Error(toTestingStatus(err).message);
        })
      )
      .subscribe({
        next: () => {
          setAlertAttributes({ severity: 'success', title: successMessage });
          setErrorOrHideModal('');
        },
        error: (error: Error) => {
          setErrorOrHideModal(error.message.replace(/^db query error: pq: /, ''));
        },
      });

  const escape = (str: string) => str.replaceAll("'", "''");
  const tz = getTimeZoneInfo(timeZone, Date.now());

  const setMonitorArea = (
    optionalMonitorUuid: string,
    change: ChangeAreaDTO,
    setErrorOrHideModal: (err: string) => void
  ) => {
    const area = change.area?.value || '';

    const defaultProperties = {
      holidaysCountry: defaultCountryFromTimeZone(tz),
      timeZone: defaultTimeZone(tz),
    } as SettingsDTO;
    ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].forEach((d) =>
      [1, 2, 3, 4].forEach((n) => {
        // @ts-ignore: ignore type checks
        defaultProperties[d + n] = defaultOpenHour(d, n);
      })
    );
    query(
      'CALL set_monitor_thing(' +
        (change.token ? "'" + change.token + "'" : 'NULL') +
        ',' +
        (optionalMonitorUuid ? "'" + optionalMonitorUuid + "'::uuid" : 'NULL') +
        ",'" +
        escape(area) +
        "',FALSE,'" +
        escape(change.replaceArea?.value || '') +
        "','" +
        JSON.stringify(geojson(change)) +
        "'::json,'" +
        JSON.stringify(toThingProps(defaultProperties)) +
        "'::jsonb);",
      t('monitor_settings_success', { area: area }),
      setErrorOrHideModal
    );
  };

  const renameArea = (oldName: string, updatedName: string, setErrorOrHideModal: (err: string) => void) =>
    query(
      'UPDATE "THINGS" SET "NAME"=\'' +
        escape(updatedName) +
        '\' WHERE "NAME"=\'' +
        escape(oldName) +
        '\' AND "NAME" != \'' +
        escape(updatedName) +
        "';",
      t('rename_success', { updatedName: updatedName }),
      setErrorOrHideModal
    );

  const definedSettings = (thingProperties: SettingsDTO) =>
    Object.entries(thingProperties).reduce((result, entry) => {
      if (entry[1] !== auto) {
        result[entry[0]] = entry[1];
      }
      return result;
    }, {} as any);

  const undefinedSettingKeys = (thingProperties: SettingsDTO) =>
    Object.entries(thingProperties)
      .filter((e) => e[1] === auto || e[1] === undefined)
      .map((e) => e[0]);

  const toThingProps = (settings: SettingsDTO) => {
    const props = { ...settings } as any;
    delete props.longitude;
    delete props.latitude;
    delete props.altitude;
    props.openingHours = {};
    [
      'Mon1',
      'Tue1',
      'Wed1',
      'Thu1',
      'Fri1',
      'Sat1',
      'Sun1',
      'Mon3',
      'Tue3',
      'Wed3',
      'Thu3',
      'Fri3',
      'Sat3',
      'Sun3',
    ].forEach((field1) => {
      const time1 = props[field1] as string;
      delete props[field1];
      const dayofWeek = field1.substring(0, 3);
      const index = parseInt(field1.charAt(3), 10);
      const field2 = dayofWeek + (index + 1);
      const time2 = props[field2] as string;
      delete props[field2];
      if (time1 && time2) {
        props.openingHours[dayofWeek] = props.openingHours[dayofWeek] ?? [];
        props.openingHours[dayofWeek].push(time1 + '-' + time2);
      }
    });
    for (let [key, def] of Object.entries(SETTINGS_DEFAULT)) {
      if (props[key] === def) {
        props[key] = undefined;
      }
    }
    for (let [key, factor] of Object.entries(FORM_TO_DB_FACTOR)) {
      if (props[key]) {
        props[key] = Math.round(props[key] * factor);
      }
    }
    return props;
  };

  const setSettings = (thingUuid: string, settings: SettingsDTO, setErrorOrHideModal: (err: string) => void) => {
    const thingProps = toThingProps(settings);
    let sql =
      'UPDATE "THINGS" SET "PROPERTIES"= ("PROPERTIES" || \'' +
      // undefined properties are not serialized in stringify
      JSON.stringify(definedSettings(thingProps)) +
      "'::jsonb) - '{" +
      undefinedSettingKeys(thingProps).join(',') +
      "}'::text[]" +
      ' WHERE "ID"=\'' +
      thingUuid +
      "'::uuid;";
    sql +=
      'CALL ' +
      (settings.mobile ? 'insert' : 'upsert') +
      "_location('" +
      thingUuid +
      "'::uuid,'" +
      thingUuid +
      " location','Set by app on " +
      new Date().toISOString() +
      "','" +
      JSON.stringify(geojson(settings)) +
      "'::json);";
    return query(sql, t('set_settings_success'), setErrorOrHideModal);
  };

  const urlParamsCleanup = () => {
    locationService.partial({ 'var-monitorUuid': '', 'var-monitorToken': '', 'var-thingUuid': '' }, true);
    // force table update
    locationService.reload();
  };

  const renderAreaSettingsModal = () => (
    <ModalsController>
      {({ showModal, hideModal }) => {
        const showThingSettingsModal = (modalThingUuid: string, area: string, settings: SettingsDTO) =>
          showModal(AreaSettingsModal, {
            area: area,
            thingUuid: modalThingUuid,
            previousSettings: settings,
            dashboardTimeZone: tz,
            hideModal: () => {
              urlParamsCleanup();
              hideModal();
              setThingModalShown(false);
            },
            setSettings: setSettings,
            isOpen: true,
          });
        setThingModalShown(true);
        const thingUuid = urlVariables.thingUuid;
        setUrlVariables({ thingUuid: '', monitorUuid: '', token: '' });
        const thingDetails = findThingDetails(thingUuid!, data, options.frameIndex);
        showThingSettingsModal(thingUuid!, thingDetails.area, thingDetails.settings);
        return <></>;
      }}
    </ModalsController>
  );

  const renderChangeAreaButtonAndModal = () => (
    <ModalsController>
      {({ showModal, hideModal }) => {
        const showAreaModal = (modalMonitorToken: string, modalMonitorUuid: string) => {
          setAreaModalShown(true);
          showModal(ChangeAreaModal, {
            monitorToken: modalMonitorToken,
            monitorUuid: modalMonitorUuid,
            hideModal: () => {
              urlParamsCleanup();
              hideModal();
              setAreaModalShown(false);
            },
            setMonitorArea: setMonitorArea,
            renameArea: renameArea,
            isOpen: true,
          });
        };
        if (urlVariables.token || urlVariables.monitorUuid) {
          showAreaModal(urlVariables.token || '', urlVariables.monitorUuid || '');
        }
        return (
          <Button
            id="meo_add_monitor"
            variant="primary"
            fill="solid"
            size="md"
            key="add-monitor"
            onClick={(e) => {
              e.preventDefault();
              showAreaModal('', '');
            }}
          >
            {t('add_monitor_button')}
          </Button>
        );
      }}
    </ModalsController>
  );

  const onColumnResize = (fieldDisplayName: string, newWidth: number) => {
    const { overrides } = fieldConfig;

    const matcherId = FieldMatcherID.byName;
    const propId = 'custom.width';

    // look for existing override
    const override = overrides.find((o) => o.matcher.id === matcherId && o.matcher.options === fieldDisplayName);

    if (override) {
      // look for existing property
      const property = override.properties.find((prop) => prop.id === propId);
      if (property) {
        property.value = newWidth;
      } else {
        override.properties.push({ id: propId, value: newWidth });
      }
    } else {
      overrides.push({
        matcher: { id: matcherId, options: fieldDisplayName },
        properties: [{ id: propId, value: newWidth }],
      });
    }

    onFieldConfigChange({
      ...fieldConfig,
      overrides,
    });
  };

  const onSortByChange = (sortBy: TableSortByFieldState[]) => {
    onOptionsChange({
      ...options,
      sortBy,
    });
  };

  const padding = 8 * 2;

  const hasFields = (panelData: PanelData) => panelData.series?.length > 0 && panelData.series[0]?.fields.length > 0;

  return (
    <div className={styles.wrapper}>
      <VerticalGroup>
        {alertAttributes && <Alert {...alertAttributes} onRemove={() => setAlertAttributes(undefined)} />}
        <HorizontalGroup spacing="lg" wrap={true} align={hasFields(data) ? 'flex-start' : 'center'}>
          {!thingModalShown && urlVariables.thingUuid && renderAreaSettingsModal()}
          {!areaModalShown && renderChangeAreaButtonAndModal()}
          {hasFields(data) &&
            toggleKeys.map((k) => (
              <InlineSwitch
                key={'meo_' + k}
                id={'meo_' + k}
                label={t('toggle_' + k)}
                showLabel={true}
                value={toggles.has(k)}
                onChange={(e) =>
                  setToggles((current) => {
                    const update = new Set(current);
                    e.currentTarget.checked ? update.add(k) : update.delete(k);
                    return update;
                  })
                }
              />
            ))}
        </HorizontalGroup>
        {hasFields(data) && (
          <Table
            height={height - config.theme.spacing.formInputHeight - padding}
            width={width}
            data={filteredData}
            noHeader={false}
            resizable={true}
            initialSortBy={options.sortBy}
            onSortByChange={onSortByChange}
            onColumnResize={onColumnResize}
            // onCellFilterAdded={onCellFilterAdded}
          />
        )}
      </VerticalGroup>
    </div>
  );
};

function getStyles() {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    `,
    noData: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    `,
  };
}
