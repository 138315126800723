import { css, cx } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Stack } from '@grafana/experimental';
import {
  Checkbox,
  Field,
  Icon,
  Input,
  InputControl,
  Label,
  Legend,
  ReactUtils,
  Tooltip,
  useStyles2,
} from '@grafana/ui';
import { SETTINGS_DEFAULT, SettingsDTO } from 'components/AreaSettingsModal';
import React, { HTMLProps, ReactElement, ReactNode } from 'react';
import {
  Control,
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import { NUM_WIDTH, getCommonStyles } from 'utils.styles';

export const NBSP = '\xa0';

export const REQUIRED_BOOLEAN = {
  validate: (v: any) => {
    if (typeof v === 'undefined') {
      return 'Champ obligatoire.';
    }
    return true;
  },
};

export const STRICTLY_POSITIVE = {
  validate: (v: any) => {
    if (typeof v !== 'undefined' && v <= 0) {
      return 'Le nombre doit être strictement positif.';
    }
    return true;
  },
};

export const POSITIVE = {
  validate: (v: any) => {
    if (typeof v !== 'undefined' && v < 0) {
      return 'Le nombre ne peut pas être négatif.';
    }
    return true;
  },
};

export const TARGET_BLANK = { target: '_blank', rel: 'noreferrer' };

export const makeOptions = (values: string[][]) => values.map((v) => ({ label: v[0], value: v[0], description: v[1] }));

export const makeI18nOptions = (t: TFunction<'translation', undefined>, prefix: string, values: string[]) =>
  values.map((v) => ({ label: t(prefix + v + '_label'), value: v, description: t(prefix + v + '_description') }));

export interface SmallFieldSetProps extends Omit<HTMLProps<HTMLFieldSetElement>, 'label'> {
  children: ReactNode[] | ReactNode;
  label: ReactNode;
  tooltip?: string | React.ReactElement;
}

const getSmallFieldSetStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    border: 1px solid ${theme.colors.border.strong};
    padding: 0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
  legend: css`
    font-size: ${theme.typography.h5.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    margin: 0 0 ${theme.spacing(2)} 0;
    padding-left: ${theme.spacing(1)};
  `,
});

//background-color: ${theme.colors.background.secondary};

/** Field set with bold text in smaller font and smaller margins. */
export const SmallFieldSet = ({ label, children, className, tooltip, ...rest }: SmallFieldSetProps) => {
  const styles = useStyles2(getSmallFieldSetStyles);
  return (
    <fieldset className={cx(styles.wrapper, className)} {...rest}>
      {label && (
        <Legend className={cx(styles.legend)}>
          <Stack gap={0.5}>
            {label}
            <InfoTooltip tooltip={tooltip} />
          </Stack>
        </Legend>
      )}
      {children}
    </fieldset>
  );
};

export const InfoTooltip = ({ tooltip }: { tooltip?: string | ReactElement }) => {
  const styles = useStyles2(getTooltipFieldStyles);
  return tooltip ? (
    <Tooltip content={<div>{tooltip}</div>} interactive>
      <Icon className={styles.icon} name="info-circle" size="sm" />
    </Tooltip>
  ) : (
    <></>
  );
};
const getTooltipFieldStyles = (theme: GrafanaTheme2) => ({
  icon: css`
    margin-right: ${theme.spacing(0.5)};
  `,
});
export interface TooltipLabelProps {
  label: ReactElement | string;
  className?: string;
  childrenForId?: ReactElement;
  /** Children is mandatory to get description */
  description?: ReactNode;
  tooltip?: string | ReactElement;
}

export const TooltipLabel = ({ label, className, tooltip, description, childrenForId }: TooltipLabelProps) => {
  return childrenForId ? (
    <Label className={className} htmlFor={ReactUtils.getChildId(childrenForId)} description={description}>
      <Stack gap={0.5}>
        {label}
        <InfoTooltip tooltip={tooltip} />{' '}
      </Stack>
    </Label>
  ) : (
    <Stack gap={0.5}>
      <span className={className}>{label}</span>
      & <InfoTooltip tooltip={tooltip} />
    </Stack>
  );
};

export interface TooltipFieldProps {
  children: React.ReactElement;
  label: React.ReactElement | string;
  error: FieldError | undefined;
  tooltip?: string | React.ReactElement;
  description?: React.ReactNode;
  disabled?: boolean;
}

export const TooltipField = ({ label, description, disabled, error, tooltip, children }: TooltipFieldProps) => {
  const styles = useStyles2(getCommonStyles);
  return (
    <Field
      label={
        <TooltipLabel
          className={styles.field}
          description={description}
          tooltip={tooltip}
          label={label}
          childrenForId={children}
        />
      }
      invalid={!!error}
      error={error?.message}
      disabled={disabled}
    >
      {children}
    </Field>
  );
};

export const TooltipCheckbox = ({
  name,
  label,
  description,
  tooltip,
  defaultChecked,
  control,
  options,
  dependentName,
  setValue,
  ...rest
}: {
  name: string;
  label: string;
  control: Control<any>;
  options?: RegisterOptions;
  description?: string;
  tooltip?: string | ReactElement;
  defaultChecked?: boolean;
  dependentName?: string;
  setValue?: UseFormSetValue<any>;
}) => {
  const styles = useStyles2(getCommonStyles);
  return (
    <Stack gap={0.5}>
      <InputControl
        name={name}
        defaultValue={defaultChecked}
        control={control}
        rules={options}
        render={({ field: { onChange, ...field } }) => (
          <Checkbox
            {...field}
            className={styles.checkbox}
            label={label}
            description={description}
            onChange={(e) => {
              onChange(e);
              if (dependentName && setValue) {
                setValue(dependentName, e.currentTarget.checked);
              }
            }}
            {...rest}
          />
        )}
      />
      <InfoTooltip tooltip={tooltip} />
    </Stack>
  );
};

const selectableValueToString = (selectableValue: SelectableValue<string>): string => selectableValue.value!;

const selectableValuesToStrings = (arr: Array<SelectableValue<string>> | undefined): string[] =>
  (arr ?? []).map(selectableValueToString);

export const mapMultiSelectValueToStrings = (
  selectableValues: Array<SelectableValue<string>> | undefined
): string[] => {
  if (!selectableValues) {
    return [];
  }

  return selectableValuesToStrings(selectableValues);
};

interface NumberSettingProps {
  field: Path<SettingsDTO>;
  min: number;
  max: number;
  step: number;
  defaultValues: SettingsDTO;
  errors: DeepMap<SettingsDTO, FieldError>;
  register: UseFormRegister<SettingsDTO>;
  otherValidate?: (v: any) => boolean | string;
  i18nPrefix?: string;
}

export const DU_PRESENCE_VALIDATE = (t: TFunction<'translation', undefined>, min: any, presence: any, max: any) => {
  if (min <= presence && presence <= max) {
    return true;
  }
  return t('dUPresence_validation');
};

export function NumberField<T extends FieldValues>({
  field,
  min,
  max,
  otherValidate,
  step,
  defaultValues,
  errors,
  register,
  i18nPrefix,
}: NumberSettingProps) {
  const { t } = useTranslation();
  const i18nField = (i18nPrefix ?? '') + field;
  return (
    <TooltipField
      label={t(i18nField + '_label')}
      tooltip={<Trans i18nKey={i18nField + '_tooltip'} />}
      error={errors[field]}
    >
      <Input
        {...register(field, {
          validate: (v: any) => {
            if (typeof v !== 'undefined' && v < min) {
              return 'Le nombre doit être supérieur à ' + min;
            }
            if (typeof v !== 'undefined' && v > max) {
              return 'Le nombre doit être inférieur à ' + max;
            }
            return otherValidate ? otherValidate(v) : true;
          },
          valueAsNumber: true,
        })}
        defaultValue={(defaultValues[field] ?? SETTINGS_DEFAULT[field]) as number | undefined}
        width={NUM_WIDTH}
        type="number"
        min={min}
        max={max}
        step={step}
      />
    </TooltipField>
  );
}
