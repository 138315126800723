import { QrReader } from '@blackbox-vision/react-qr-reader';
import { css } from '@emotion/css';
import { DataFrame, GrafanaTheme2, SelectableValue } from '@grafana/data';
import { BackendDataSourceResponse, getBackendSrv, getDataSourceSrv, toDataQueryResponse } from '@grafana/runtime';
import {
  Alert,
  Button,
  Card,
  Form,
  HorizontalGroup,
  Icon,
  Input,
  InputControl,
  Label,
  LoadingPlaceholder,
  Modal,
  RadioButtonGroup,
  Select,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import React, { useEffect, useState } from 'react';
import { Control, DeepMap, FieldError, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { from, throwError } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { toTestingStatus } from '../TestingStatus';
import { Coordinates } from './Coordinates';
import { ViewFinder } from './ViewFinder';
import { TooltipField } from 'utils.form';

interface Props {
  monitorToken: string;
  monitorUuid: string;
  hideModal: () => void;
  setMonitorArea: (monitorUuid: string, change: ChangeAreaDTO, setError: (err: string) => void) => void;
  renameArea: (oldName: string, updatedName: string, setError: (err: string) => void) => void;
  isOpen: boolean;
}

export interface ChangeAreaDTO {
  token?: string;
  renameMove?: 'rename' | 'move';
  // area for both monitor add and move
  area?: SelectableValue<string>;
  renameArea?: string;
  replaceArea?: SelectableValue<string>;
  longitude?: number;
  latitude?: number;
  altitude?: number;
}

export interface PostResponse {
  results: Record<string, any>;
}

const fetchAreas =
  // from default datasource for now, a specific datasource could be added in panel options
  from(getDataSourceSrv().get(null))
    .pipe(take(1))
    .pipe(
      mergeMap((ds) =>
        getBackendSrv().fetch<BackendDataSourceResponse>({
          method: 'POST',
          url: '/api/ds/query',
          data: {
            queries: [
              {
                datasourceId: ds.id,
                refId: 'A',
                rawSql:
                  'SELECT "NAME", (SELECT json_build_object(\'monitorUuid\',thing_monitor_id::text,' +
                  "'monitorToken',thing_monitor_token," +
                  "'monitorSerialNum',monitor_serial_num(thing_monitor_id)," +
                  "'monitorMovedToOtherArea',last_monitor_moved_to_other_thing)" +
                  ' FROM thing_monitor("ID")) AS thing_monitor FROM "THINGS";',
                format: 'table',
              },
            ],
          },
        })
      ),
      catchError((err) => {
        return throwError(() => new Error(toTestingStatus(err).message));
      })
    );

const toExistingAndSelectableAreas = (
  allAreas: DataFrame,
  token: string | undefined,
  monitorUuid: string | undefined
) => {
  const values: Array<SelectableValue<string>> = [];
  const nameField = allAreas.fields.find((f) => f.name === 'NAME');
  const areaMonitorField = allAreas.fields.find((f) => f.name === 'thing_monitor');
  let existingArea = undefined;
  if (nameField) {
    for (let i = 0; i < nameField.values.length; i++) {
      const areaName = nameField.values.get(i);
      const value: SelectableValue<string> = {
        value: '' + areaName,
        label: '' + areaName,
      };
      if (areaMonitorField) {
        const areaMonitor = JSON.parse(areaMonitorField.values.get(i));
        value.monitorSerialNum = areaMonitor.monitorSerialNum;
        value.monitorMovedToOtherArea = areaMonitor.monitorMovedToOtherArea;
        if (
          (areaMonitor.monitorUuid === monitorUuid || areaMonitor.monitorToken === token) &&
          !areaMonitor.monitorMovedToOtherArea
        ) {
          existingArea = value;
          continue;
        }
      }
      values.push(value);
    }
  }
  return { existing: existingArea, selectable: values };
};

export function ChangeAreaModal({ monitorToken, monitorUuid, hideModal, setMonitorArea, renameArea, isOpen }: Props) {
  const styles = useStyles2(getStyles);
  const [defaultToken, setDefaultToken] = useState(monitorToken);
  const [showForm, setShowForm] = useState(false);
  const [allAreas, setAllAreas] = useState<DataFrame | undefined>(undefined);
  const [areas, setAreas] = useState<Array<SelectableValue<string>>>([]);
  const [replaceAreas, setreplaceAreas] = useState<Array<SelectableValue<string>>>([]);
  const [alert, setAlert] = useState('');
  const [warning, setWarning] = useState('');
  const [existingArea, setExistingArea] = useState<SelectableValue<string> | undefined>(undefined);
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation();

  const replaceAreaRequired = (area: SelectableValue<string> | undefined) =>
    area?.monitorSerialNum && !area?.monitorMovedToOtherArea;

  const trimToken = (aToken: string) => aToken.replace(/[^1-9A-HJ-NP-Za-km-z]/g, '');

  const validateToken = (aToken: string | undefined): string | boolean => {
    if (monitorUuid) {
      return true;
    }
    if (!aToken || aToken.length === 0) {
      return t<string>('cannot_be_blank');
    }
    const trimmedToken = trimToken(aToken);
    if (trimmedToken.length === 8) {
      return t<string>('eight_chars_token', { trimmed: trimmedToken });
    }
    if (trimmedToken.length !== 12) {
      return t<string>('bad_token_length', { trimmed: trimmedToken });
    }
    return true;
  };

  const validateArea = (oldArea: string | undefined, newArea: string | undefined): string | boolean => {
    if (!newArea || newArea.trim().length === 0) {
      return t<string>('cannot_be_blank');
    }
    if (newArea === oldArea) {
      return t<string>('cannot_be_same_area', { oldArea: oldArea });
    }
    return true;
  };

  useEffect(() => {
    fetchAreas.subscribe({
      next: (resp) => {
        const frames = toDataQueryResponse(resp).data as DataFrame[];
        if (!frames?.length) {
          setAllAreas({ fields: [], length: 0 });
        } else {
          setAllAreas(frames[0]);
        }
      },
      error: (error) => setAlert('' + error),
    });
  }, []);

  useEffect(() => {
    if (allAreas) {
      const splitAreas = toExistingAndSelectableAreas(allAreas, defaultToken, monitorUuid);
      setAreas(splitAreas.selectable);
      setExistingArea(splitAreas.existing);
    }
  }, [allAreas, defaultToken, monitorUuid]);

  const renderRename = (
    register: UseFormRegister<ChangeAreaDTO>,
    errors: DeepMap<ChangeAreaDTO, FieldError>,
    watch: UseFormWatch<ChangeAreaDTO>
  ) => (
    <>
      <TooltipField
        disabled={watch().renameMove === 'move'}
        error={errors.renameArea}
        label={t('rename_area_label')}
        description={<Trans i18nKey="rename_area_description" values={{ area: existingArea?.value }} />}
      >
        <Input
          {...register('renameArea', {
            validate: (v) => validateArea(existingArea?.value, v),
          })}
          autoFocus={true}
          defaultValue={existingArea?.value}
        />
      </TooltipField>
    </>
  );

  const replaceAreaDefault = (watch: UseFormWatch<ChangeAreaDTO>): SelectableValue<string> => {
    const defaultValue = t('replace_area_default', {
      monitorSerialNum: watch('area', existingArea)?.monitorSerialNum,
    });
    return { value: defaultValue, label: defaultValue };
  };

  const renderAreaFields = (
    disabled: boolean,
    register: UseFormRegister<ChangeAreaDTO>,
    errors: DeepMap<ChangeAreaDTO, FieldError>,
    control: Control<ChangeAreaDTO>,
    watch: UseFormWatch<ChangeAreaDTO>,
    setValue: UseFormSetValue<ChangeAreaDTO>
  ) => (
    <>
      <TooltipField
        disabled={disabled}
        error={errors.area?.value}
        label={t('area_label')}
        description={<Trans i18nKey="area_description" />}
      >
        <InputControl
          render={({ field: { onChange, ref, ...field } }) => (
            <Select
              {...field}
              onChange={(v) => {
                onChange(v);
                setreplaceAreas(areas.filter((a) => a.value !== v?.value && a.monitorMovedToOtherArea));
              }}
              placeholder={t('area_placeholder', { count: 1 + areas.length })}
              options={areas}
              allowCustomValue={true}
              formatCreateLabel={(s) => t('select_create', { input: s })}
              onCreateOption={(v) => {
                onChange({ value: v, label: v });
                setValue('replaceArea', undefined);
              }}
              autoFocus={!!monitorUuid || !!defaultToken}
            />
          )}
          control={control}
          name="area"
          defaultValue={existingArea?.value}
          rules={{ validate: (v) => validateArea(existingArea?.value, v?.value) }}
        />
      </TooltipField>
      {!watch('area', existingArea)?.monitorSerialNum && (
        <Coordinates setValue={setValue} register={register} errors={errors} defaultCoords={{}} />
      )}
      {/* Fake Demand Control Ventilation fields when area ends with (DCV) */}
      {existingArea?.value?.endsWith('(DCV)') && (
        <HorizontalGroup>
          <TooltipField
            label={
              <Label>
                {t('dc_name_prefix')}
                <abbr title={t('dc_abbr_title')}>{t('dc_abbr')}</abbr>
                {t('dc_name_suffix')}
              </Label>
            }
            error={undefined}
          >
            <Input />
          </TooltipField>
          <TooltipField
            label={
              <Label>
                {t('dc_id_prefix')}
                <abbr title={t('dc_abbr_title')}>{t('dc_abbr')}</abbr>
                {t('dc_id_suffix')}
              </Label>
            }
            error={undefined}
          >
            <Input />
          </TooltipField>
        </HorizontalGroup>
      )}
      {replaceAreaRequired(watch('area', existingArea)) && (
        <TooltipField
          error={errors.replaceArea?.value}
          label={t('replace_area_label')}
          description={
            <Trans
              i18nKey="replace_area_description"
              values={{
                count: 1 + areas.length,
                monitorSerialNum: watch('area', existingArea)?.monitorSerialNum,
                area: watch('area', existingArea)?.value,
              }}
            />
          }
        >
          <InputControl
            render={({ field: { onChange, ref, ...field } }) => (
              <Select
                {...field}
                placeholder={t('replace_area_placeholder', { count: 1 + replaceAreas.length })}
                onChange={(v) => onChange(v)}
                options={[replaceAreaDefault(watch)].concat(replaceAreas)}
                allowCustomValue={true}
                formatCreateLabel={(s) => t('select_create', { input: s })}
                onCreateOption={(v) => {
                  onChange({ value: v, label: v });
                }}
              />
            )}
            control={control}
            name="replaceArea"
            defaultValue={replaceAreaDefault(watch).value}
            rules={{ validate: (v) => validateArea(watch('area', existingArea)?.value, v?.value) }}
          />
        </TooltipField>
      )}
    </>
  );

  const renderMove = (
    register: UseFormRegister<ChangeAreaDTO>,
    errors: DeepMap<ChangeAreaDTO, FieldError>,
    control: Control<ChangeAreaDTO>,
    watch: UseFormWatch<ChangeAreaDTO>,
    setValue: UseFormSetValue<ChangeAreaDTO>
  ) => {
    const disabled = !!existingArea?.value && watch().renameMove === 'rename';
    return renderAreaFields(disabled, register, errors, control, watch, setValue);
  };

  const renderMoveRename = (
    register: UseFormRegister<ChangeAreaDTO>,
    errors: DeepMap<ChangeAreaDTO, FieldError>,
    control: Control<ChangeAreaDTO>,
    watch: UseFormWatch<ChangeAreaDTO>,
    setValue: UseFormSetValue<ChangeAreaDTO>
  ) => {
    switch (watch().renameMove) {
      case 'rename':
        return renderRename(register, errors, watch);
      case 'move':
        return renderMove(register, errors, control, watch, setValue);
      default:
        return <></>;
    }
  };

  const renderRenameMove = (
    register: UseFormRegister<ChangeAreaDTO>,
    errors: DeepMap<ChangeAreaDTO, FieldError>,
    control: Control<ChangeAreaDTO>,
    watch: UseFormWatch<ChangeAreaDTO>,
    setValue: UseFormSetValue<ChangeAreaDTO>
  ) => {
    const options = [
      {
        label: t('rename_label'),
        value: 'rename',
        icon: 'comment-alt',
      },
      {
        label: t('move_label'),
        value: 'move',
        icon: 'library-panel',
      },
    ];
    return (
      <>
        <TooltipField
          disabled={!existingArea?.value}
          error={errors.renameMove}
          label={t('rename_move_label')}
          description={
            <p>
              {t('rename_move_description_intro')}
              <ul className={styles.list}>
                <li>
                  <strong>
                    <Icon name="comment-alt" className={styles.icon} />
                    {t('rename_label')}
                  </strong>
                  {t('rename_move_description_rename_intro')}
                  <ul className={styles.nestedlist}>
                    <li>
                      <Trans i18nKey="rename_move_description_rename_1" />
                    </li>
                    <li>
                      <Trans i18nKey="rename_move_description_rename_2" />
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>
                    <Icon name="library-panel" className={styles.icon} />
                    {t('move_label')}
                  </strong>
                  {t('rename_move_description_move')}
                </li>
              </ul>
            </p>
          }
        >
          <InputControl
            render={({ field: { ref, ...field } }) => (
              <RadioButtonGroup {...field} options={options} fullWidth={true} />
            )}
            control={control}
            name="renameMove"
            rules={{
              validate: (v) => v !== undefined || t<string>('required_rename_move'),
            }}
          />
        </TooltipField>
        {renderMoveRename(register, errors, control, watch, setValue)}
        {watch().renameMove && renderInfo(watch)}
        {watch().renameMove === 'move' && renderWarning(watch)}
      </>
    );
  };

  const renderInfo = (watch: UseFormWatch<ChangeAreaDTO>) => (
    <Alert title={t('info_title')} severity="info">
      <p>
        <Trans i18nKey="info_intro" values={{ monitorSerialNum: existingArea?.monitorSerialNum }} />
        {watch().renameMove === 'rename' && (
          <ul className={styles.list}>
            <li>
              <Trans i18nKey="info_rename_1" values={{ updatedName: watch('renameArea', existingArea?.value) }} />
            </li>
          </ul>
        )}
        {watch().renameMove === 'move' && (
          <ul className={styles.list}>
            <li>
              <Trans i18nKey="info_move_1" values={{ previousArea: existingArea?.value }} />
            </li>
            <li>
              <Trans
                i18nKey="info_move_2"
                values={{
                  newArea: watch('area', existingArea)?.value || t('area_placeholder', { count: 1 + areas.length }),
                }}
              />
            </li>
          </ul>
        )}
      </p>
      {watch().renameMove === 'move' && watch('replaceArea', replaceAreaDefault(watch)) && (
        <p>
          <Trans
            i18nKey="info_move_replace_intro"
            values={{ monitorSerialNum: watch('area', existingArea)?.monitorSerialNum }}
          />
          <ul className={styles.list}>
            <li>
              <Trans
                i18nKey="info_move_replace_1"
                values={{
                  previousArea:
                    watch('area', existingArea)?.value || t('area_placeholder', { count: 1 + areas.length }),
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="info_move_replace_2"
                values={{
                  newArea:
                    watch('replaceArea', replaceAreaDefault(watch))?.value ||
                    t('replace_area_placeholder', { count: 1 + replaceAreas.length }),
                }}
              />
            </li>
          </ul>
        </p>
      )}
    </Alert>
  );

  const renderWarning = (watch: UseFormWatch<ChangeAreaDTO>) => {
    const monitorCount = watch('replaceArea', replaceAreaDefault(watch)) ? 2 : 1;
    return (
      <Alert title={t('warn_title', { count: monitorCount })} severity="warning">
        <p>
          <Trans
            i18nKey="warn_intro"
            values={{
              count: monitorCount,
              monitorSerialNum: existingArea?.monitorSerialNum,
              replaceMonitorSerialNum: watch('area', existingArea)?.monitorSerialNum,
            }}
          />
          <ol className={styles.list}>
            <li>
              <Trans
                i18nKey="warn_power_off"
                values={{
                  count: monitorCount,
                }}
              />
              <ul className={styles.list}>
                <li>
                  <Trans i18nKey="warn_unplug" />
                </li>
                <li>
                  <Trans i18nKey="warn_remove_batteries" />
                </li>
              </ul>
            </li>
            <li>
              <Trans
                i18nKey="warn_change_settings"
                values={{
                  count: monitorCount,
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="warn_move"
                values={{
                  count: monitorCount,
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="warn_power_on"
                values={{
                  count: monitorCount,
                }}
              />
            </li>
          </ol>
        </p>
        <p>
          <Trans
            i18nKey="warn_risk"
            values={{
              count: monitorCount,
            }}
          />
        </p>
      </Alert>
    );
  };
  if (!allAreas) {
    return <LoadingPlaceholder text={t('loading')} />;
  }

  const changeArea = (change: ChangeAreaDTO) => {
    const setErrorOrHide = (monitorAddError: string) => {
      if (monitorAddError) {
        setAlert(monitorAddError);
        setSubmitted(false);
      } else {
        hideModal();
      }
    };
    if (!existingArea?.value || change.renameMove === 'move') {
      setMonitorArea(monitorUuid, change, setErrorOrHide);
    } else if (change.renameMove === 'rename') {
      renameArea(existingArea?.value, change.renameArea || '', setErrorOrHide);
    }
  };

  const onQrReaderResult = (result: any, error: any) => {
    if (!!result) {
      const url = new URL(result.getText());
      const parsedToken = url.searchParams.get('var-monitorToken');
      if (parsedToken) {
        setDefaultToken(parsedToken);
      } else {
        setAlert(t('scan_invalid_qr_code'));
        setShowForm(true);
      }
    }
    if (error?.message) {
      switch (error.name) {
        case 'NotFoundError':
          setShowForm(true);
          break;
        case 'TypeError':
          setWarning(t('scan_invalid_qr_code'));
          setShowForm(true);
          break;
        case 'NotAllowedError':
          setWarning(t('scan_permission_error'));
          setShowForm(true);
          break;
        default:
          setWarning(t('scan_error', { error: error.name + ' - ' + error.message }));
          setShowForm(true);
      }
    }
  };

  return (
    <Modal
      title={
        <span className={styles.title}>
          <Trans
            i18nKey={existingArea?.value ? 'change_area_modal_title_update' : 'change_area_modal_title_new'}
            values={{ area: existingArea?.value }}
          />
        </span>
      }
      closeOnBackdropClick={false}
      onDismiss={hideModal}
      isOpen={isOpen}
    >
      {warning && <Alert title={warning} severity="warning" />}
      <div className={styles.container}>
        {showForm || monitorUuid || defaultToken ? (
          <Form<ChangeAreaDTO>
            maxWidth="none"
            onSubmit={async (change: ChangeAreaDTO) => {
              setSubmitted(true);
              changeArea(change);
            }}
          >
            {({ register, errors, control, watch, setValue }) => (
              <>
                {!monitorUuid && (
                  <TooltipField
                    label={t('token_label')}
                    description={<Trans i18nKey="token_description" />}
                    error={errors.token}
                  >
                    <Input
                      defaultValue={defaultToken}
                      {...register('token', {
                        setValueAs: (val) => {
                          const trimmed = trimToken(val);
                          const splitAreas = toExistingAndSelectableAreas(allAreas, trimmed, monitorUuid);
                          setAreas(splitAreas.selectable);
                          setExistingArea(splitAreas.existing);
                          return trimmed;
                        },
                        validate: validateToken,
                      })}
                      placeholder={t('token_placeholder')}
                      autoFocus={!defaultToken}
                    />
                  </TooltipField>
                )}
                {existingArea?.value
                  ? renderRenameMove(register, errors, control, watch, setValue)
                  : renderAreaFields(false, register, errors, control, watch, setValue)}
                {alert && <Alert title={alert} severity="error" onRemove={() => setAlert('')} />}
                <Modal.ButtonRow>
                  <Button type="button" disabled={submitted} onClick={hideModal} variant="secondary">
                    {t('cancel')}
                  </Button>
                  <Button type="submit" disabled={submitted}>
                    {submitted ? t('submitted') : t('ok')}
                  </Button>
                </Modal.ButtonRow>
              </>
            )}
          </Form>
        ) : (
          <VerticalGroup>
            <Card>
              <Card.Heading>
                <VerticalGroup>
                  <Trans i18nKey="scanner_card_start" />
                  <ul className={styles.list}>
                    <li>
                      <Trans i18nKey="scanner_card_scan" />
                    </li>
                    <li>
                      <Button
                        className={styles.fallbackButton}
                        fill="text"
                        size="md"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowForm(true);
                        }}
                      >
                        {t('scanner_card_fallback')}
                      </Button>
                    </li>
                  </ul>
                </VerticalGroup>
              </Card.Heading>
            </Card>
            <div className={styles.qrReader}>
              <QrReader
                onResult={onQrReaderResult}
                ViewFinder={ViewFinder}
                videoId="video"
                scanDelay={500}
                constraints={{ facingMode: 'environment' }}
              />
            </div>
          </VerticalGroup>
        )}
      </div>
    </Modal>
  );
}
function getStyles(theme: GrafanaTheme2) {
  return {
    container: css`
      margin-bottom: 2;
    `,
    fullWidth: css`
      display: flex;
    `,
    column: css`
      width: 50%;
      padding: 5px;
    `,
    list: css`
      padding-left: 20px;
    `,
    nestedlist: css`
      padding-left: 40px;
    `,
    icon: css`
      margin-right: 0.5em;
    `,
    title: css`
      font-size: 1.6rem;
    `,
    qrReader: css`
      width: 400px;
      margin: auto;
    `,
    fallbackButton: css`
      padding-right: 0;
      padding-left: 0;
    `,
  };
}
