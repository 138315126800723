import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export const DATE_WIDTH = 15;
export const DATETIME_WIDTH = 21;
export const TXT_WIDTH = 60;
export const NUM_WIDTH = 10;
export const FORM_MAX_WIDTH = 600;

/* max character count for text areas */
export const TXT_MAX_LENGTH = 1000;

export const makeOptions = (values: string[][]) => values.map((v) => ({ label: v[0], value: v[0], description: v[1] }));

export const TEXT_AREA_TOP_BOTTOM_PADDING = 2;

export const getCommonStyles = (theme: GrafanaTheme2) => ({
  container: css`
    flex: '1 1 auto';
    width: 100%;
    height: 100%;
  `,
  fitParent: css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  title: css`
    margin-top: 1rem;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
  checkbox: css`
    span {
      font-size: 13px !important;
    }
  `,
  textarea: css`
    padding: ${TEXT_AREA_TOP_BOTTOM_PADDING}px 8px;
  `,
  field: css`
    font-size: 13px;
  `,
  fieldset: css`
    margin-bottom: 0;
    legend {
      font-size: 16px;
      padding-top: ${theme.spacing(0.5)};
    }
  `,
  list: css`
    padding-left: 20px;
  `,
  nestedlist: css`
    padding-left: 40px;
  `,
  collapse: css`
    border-color: ${theme.colors.border.medium};
  `,
  collapseDiv: css`
    padding: ${theme.spacing(1)};
  `,
  collapseLabel: css`
    font-size: ${theme.typography.h3.fontSize};
  `,
  topMargin: css`
    margin-top: ${theme.spacing(1)};
  `,
  warningDescription: css`
    margin: 0;
    color: ${theme.colors.warning.text};
  `,
  inCollapse: css`
    padding-left: 8px;
  `,
  withNewlines: css`
    white-space: pre-wrap;
  `,
  hidden: css`
    display: none;
  `,
});
