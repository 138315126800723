import { identityOverrideProcessor, PanelPlugin } from '@grafana/data';
import { TableCellDisplayMode } from '@grafana/ui';
import { TableCellHeight, TableCellOptions, defaultTableFieldOptions } from '@grafana/schema';
import './i18n';
import { TableCellOptionEditor } from './TableCellOptionEditor';
import { SettingsPanel } from './SettingsPanel';
import { Options } from './types';

const cellCategory = ['Cell options'];

export const plugin = new PanelPlugin<Options>(SettingsPanel)
  .setPanelOptions((builder) =>
    builder
      .addSelect({
        path: 'language',
        name: 'Language',
        description: 'Panel language',
        settings: {
          options: [
            { value: 'en', label: 'English' },
            { value: 'fr', label: 'Français' },
          ],
        },
        defaultValue: 'en',
      })
      .addRadio({
        path: 'cellHeight',
        name: 'Cell height',
        defaultValue: TableCellHeight.Sm,
        settings: {
          options: [
            { value: TableCellHeight.Sm, label: 'Small' },
            { value: TableCellHeight.Md, label: 'Medium' },
            { value: TableCellHeight.Lg, label: 'Large' },
          ],
        },
      })
  )
  .setNoPadding()
  .useFieldConfig({
    useCustomConfig: (builder) => {
      // copied from grafana/public/app/plugins/panel/table/module.tsx
      builder
        .addNumberInput({
          path: 'minWidth',
          name: 'Minimum column width',
          description: 'The minimum width for column auto resizing',
          settings: {
            placeholder: '150',
            min: 50,
            max: 500,
          },
          shouldApply: () => true,
          defaultValue: undefined,
        })
        .addNumberInput({
          path: 'width',
          name: 'Column width',
          settings: {
            placeholder: 'auto',
            min: 0,
            max: 300,
          },
          shouldApply: () => true,
          defaultValue: undefined,
        })
        .addRadio({
          path: 'align',
          name: 'Column alignment',
          settings: {
            options: [
              { label: 'auto', value: 'auto' },
              { label: 'left', value: 'left' },
              { label: 'center', value: 'center' },
              { label: 'right', value: 'right' },
            ],
          },
          defaultValue: 'auto',
        })
        .addCustomEditor<void, TableCellOptions>({
          id: 'cellOptions',
          path: 'cellOptions',
          name: 'Cell type',
          editor: TableCellOptionEditor,
          override: TableCellOptionEditor,
          defaultValue: defaultTableFieldOptions.cellOptions,
          process: identityOverrideProcessor,
          category: cellCategory,
          shouldApply: () => true,
        })
        .addBooleanSwitch({
          path: 'inspect',
          name: 'Cell value inspect',
          description: 'Enable cell value inspection in a modal window',
          defaultValue: false,
          category: cellCategory,
          showIf: (cfg) => {
            return (
              cfg.cellOptions.type === TableCellDisplayMode.Auto ||
              cfg.cellOptions.type === TableCellDisplayMode.JSONView ||
              cfg.cellOptions.type === TableCellDisplayMode.ColorText ||
              cfg.cellOptions.type === TableCellDisplayMode.ColorBackground
            );
          },
        })
        .addBooleanSwitch({
          path: 'filterable',
          name: 'Column filter',
          description: 'Enables/disables field filters in table',
          defaultValue: undefined,
        })
        .addBooleanSwitch({
          path: 'hidden',
          name: 'Hide in table',
          defaultValue: undefined,
          hideFromDefaults: true,
        });
    },
  });
