import { css, cx } from '@emotion/css';
import { TimeZoneInfo } from '@grafana/data';
import {
  Alert,
  Button,
  Collapse,
  ColorPicker,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  Modal,
  RadioButtonGroup,
  Select,
  Slider,
  TimeZonePicker,
  useStyles2,
} from '@grafana/ui';
import React, { useState } from 'react';
import { Control, DeepMap, FieldError, Path, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import {
  DU_PRESENCE_VALIDATE,
  NumberField,
  SmallFieldSet,
  TARGET_BLANK,
  TooltipField,
  makeI18nOptions,
} from 'utils.form';
import { getCommonStyles } from 'utils.styles';
import { Coordinates } from './Coordinates';

export const auto = -1;

type OpenHour1 = 'Mon1' | 'Tue1' | 'Wed1' | 'Thu1' | 'Fri1' | 'Sat1' | 'Sun1';
type OpenHour2 = 'Mon2' | 'Tue2' | 'Wed2' | 'Thu2' | 'Fri2' | 'Sat2' | 'Sun2';
type OpenHour3 = 'Mon3' | 'Tue3' | 'Wed3' | 'Thu3' | 'Fri3' | 'Sat3' | 'Sun3';
type OpenHour4 = 'Mon4' | 'Tue4' | 'Wed4' | 'Thu4' | 'Fri4' | 'Sat4' | 'Sun4';

export type OpenHour = OpenHour1 | OpenHour2 | OpenHour3 | OpenHour4;

export const FORM_TO_DB_FACTOR: SettingsDTO = {
  dUPresence: 10,
  dUMinCo2: 10,
  dUMaxCo2: 10,
  dUMinS2: 10,
  dUMaxS2: 10,
  dUMinS3: 10,
  dUMaxS3: 10,
};

export const SETTINGS_DEPENDENCY: { [k in keyof SettingsDTO]: SettingsDTO[] } = {
  co2Set: [{ s1: 'v' }],
  co2Presence: [{ s1: 'v' }],
  dUPresence: [{ s1: 'v' }],
  dUMinCo2: [{ s1: 'v' }, { s1: 'b' }],
  dUMaxCo2: [{ s1: 'v' }, { s1: 'b' }],
  kp: [{ s1: 'v' }],
  ti: [{ s1: 'v' }],
  db: [{ s1: 'v' }],
  co2Min: [{ s1: 'b' }],
  co2Max: [{ s1: 'b' }],
  tMin: [{ s2: 't' }],
  tMax: [{ s2: 't' }],
  dUMinS2: [{ s2: 't' }],
  dUMaxS2: [{ s2: 't' }],
  rhMin: [{ s3: 'h' }],
  rhMax: [{ s3: 'h' }],
  dUMinS3: [{ s3: 'h' }],
  dUMaxS3: [{ s3: 'h' }],
};

export const SETTINGS_DEFAULT: SettingsDTO = {
  co2T1: 800,
  co2T2: 1000,
  co2T3: 1500,
  color1: '00E400',
  color2: 'FFFF00',
  color3: 'FF7E00',
  color4: 'FF0000',
  s1: 'n',
  co2Set: 950,
  co2Presence: 500,
  dUPresence: 3,
  dUMinCo2: 2,
  dUMaxCo2: 10,
  kp: 50,
  ti: 8,
  db: 50,
  co2Min: 400,
  co2Max: 2000,
  s2: 'n',
  tMin: 0,
  tMax: 50,
  dUMinS2: 2,
  dUMaxS2: 10,
  s3: 'n',
  rhMin: 0,
  rhMax: 100,
  dUMinS3: 2,
  dUMaxS3: 10,
  sIn: 'n',
};

export interface SettingsDTO {
  color1?: string;
  co2T1?: number;
  color2?: string;
  co2T2?: number;
  color3?: string;
  co2T3?: number;
  color4?: string;
  dataFreq?: number;
  dataSyncFreq?: number;
  co2MinSync?: number;
  setupSyncFreq?: number;
  rebootFreq?: number;
  longitude?: number;
  latitude?: number;
  altitude?: number;
  // not supported for now, except to insert location instead of update
  mobile?: boolean;
  timeZone?: string;
  holidaysCountry?: string | null;
  // values are HH:MM or null when set as empty in previous settings
  Mon1?: string | null;
  Mon2?: string | null;
  Mon3?: string | null;
  Mon4?: string | null;
  Tue1?: string | null;
  Tue2?: string | null;
  Tue3?: string | null;
  Tue4?: string | null;
  Wed1?: string | null;
  Wed2?: string | null;
  Wed3?: string | null;
  Wed4?: string | null;
  Thu1?: string | null;
  Thu2?: string | null;
  Thu3?: string | null;
  Thu4?: string | null;
  Fri1?: string | null;
  Fri2?: string | null;
  Fri3?: string | null;
  Fri4?: string | null;
  Sat1?: string | null;
  Sat2?: string | null;
  Sat3?: string | null;
  Sat4?: string | null;
  Sun1?: string | null;
  Sun2?: string | null;
  Sun3?: string | null;
  Sun4?: string | null;
  // DCV
  s1?: string;
  co2Set?: number;
  co2Presence?: number;
  dUPresence?: number;
  dUMinCo2?: number;
  dUMaxCo2?: number;
  kp?: number;
  ti?: number;
  db?: number;
  co2Min?: number;
  co2Max?: number;
  s2?: string;
  tMin?: number;
  tMax?: number;
  dUMinS2?: number;
  dUMaxS2?: number;
  s3?: string;
  rhMin?: number;
  rhMax?: number;
  dUMinS3?: number;
  dUMaxS3?: number;
  sIn?: string;
}

const watchWithDefault = (watch: UseFormWatch<SettingsDTO>, field: keyof SettingsDTO, defaultValues: SettingsDTO) =>
  watch(field, defaultValues[field] ?? SETTINGS_DEFAULT[field]);

const nextThreshold = (name: 'co2T1' | 'co2T2' | 'co2T3', settings: SettingsDTO) => {
  switch (name) {
    case 'co2T1':
      return settings.co2T2;
    case 'co2T2':
      return settings.co2T3;
    default:
      return undefined;
  }
};

const previousThreshold = (name: 'co2T1' | 'co2T2' | 'co2T3', settings: SettingsDTO) => {
  switch (name) {
    case 'co2T2':
      return settings.co2T1;
    case 'co2T3':
      return settings.co2T2;
    default:
      return undefined;
  }
};

// from https://github.com/christopherthompson81/pgsql_holidays/blob/master/by_country.pgsql
const holidaysCountries = [
  'AF',
  'AD',
  'AR',
  'AW',
  'AU',
  'AT',
  'BY',
  'BE',
  'BR',
  'BG',
  'CA',
  'CL',
  'CO',
  'HR',
  'CZ',
  'DK',
  'DO',
  'EG',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'IE',
  'IL',
  'IT',
  'JP',
  'KE',
  'LT',
  'LU',
  'MX',
  'NL',
  'NZ',
  'NI',
  'NG',
  'NO',
  'PY',
  'PE',
  'PL',
  'PT',
  'RU',
  'RS',
  'SG',
  'SK',
  'SI',
  'ZA',
  'ES',
  'SE',
  'CH',
  'UA',
  'UK',
  'US',
];

export function defaultCountryFromTimeZone(tz: TimeZoneInfo | undefined) {
  const code = tz?.countries?.[0]?.code;
  return code && holidaysCountries.includes(code) ? code : undefined;
}

export function defaultTimeZone(tz: TimeZoneInfo | undefined) {
  return tz?.ianaName;
}

export function defaultOpenHour(day: string, hourNum: 1 | 2 | 3 | 4) {
  if (day.startsWith('Sat') || day.startsWith('Sun')) {
    return undefined;
  }
  switch (hourNum) {
    case 1:
      return '08:30';
    case 2:
      return '12:00';
    case 3:
      return '14:00';
    case 4:
      return '18:00';
  }
}

const labelSuffix = (v: number, specialLabelValues: number[]) => {
  if (v === auto) {
    return '_auto';
  } else if (specialLabelValues.includes(v)) {
    return '_' + v.toString().replace('.', '_');
  }
  return '';
};

const optionPrefix = (i18nFieldKey: string, specialLabelValues: number[], value: number) =>
  i18nFieldKey + '_option' + labelSuffix(value, specialLabelValues);

const translationValue = (
  t: TFunction<'translation', undefined>,
  i18nKey: string,
  specialLabelValues: number[],
  rawValue: number | undefined,
  displayToRaw: number
) => {
  const prefix = optionPrefix(i18nKey, specialLabelValues, rawValue === undefined ? auto : rawValue);
  return rawValue === auto || rawValue === undefined
    ? t(prefix + '_description')
    : t(prefix + '_label', {
        count: Math.floor(rawValue / displayToRaw),
        maximumFractionDigits: 2,
      });
};

interface TranslationOptions {
  dataFreq?: string;
  dataSyncFreq?: string;
}

const dataFreqSpecialValues: number[] = [];
const dataSyncFreqSpecialValues = [360, 1440];
const displayMinutesToRawDataFreq = 60;
const displayMinutesToRawDataSyncFreq = 1;

interface SelectDetails {
  i18nKey: string;
  // previousToRaw for the factor in query to get previous values:
  // ("THINGS"."PROPERTIES"->>'dataFreq')::float / 60  AS "energy_saving.dataFreq",
  // ("THINGS"."PROPERTIES"->>'dataSyncFreq')::float / 60 AS "energy_saving.dataSyncFreq",
  // ("THINGS"."PROPERTIES"->>'rebootFreq')::float / 24 AS "energy_saving.rebootFreq",
  previousToRaw: number;
  displayToRaw: number;
  displayValues: number[];
  valuesWithSpecialLabel: number[];
  valuesWithDescription: number[];
  translationOptions?: (
    t: TFunction<'translation', undefined>,
    watch: UseFormWatch<SettingsDTO>,
    previousSettings: SettingsDTO
  ) => TranslationOptions;
  validate?: (
    t: TFunction<'translation', undefined>,
    value: number,
    watch: UseFormWatch<SettingsDTO>
  ) => boolean | string;
}
const selectDetails: { [key: string]: SelectDetails } = {
  // display: minutes, raw: seconds, query: minutes
  dataFreq: {
    i18nKey: 'data_freq',
    previousToRaw: 60,
    displayToRaw: displayMinutesToRawDataFreq,
    displayValues: [1, 3, 5, 10],
    valuesWithSpecialLabel: dataFreqSpecialValues,
    valuesWithDescription: [auto],
    validate: (t, value, watch) => {
      // should call react-form-hook trigger to revalidate dataSyncFreq but trigger is not available in grafana-ui
      if (
        watch().dataSyncFreq !== auto &&
        value !== auto &&
        value / 60 > (watch().dataSyncFreq ?? Number.MAX_SAFE_INTEGER)
      ) {
        return t<string>('data_freq_more_than_data_sync');
      }
      return true;
    },
  },
  // display: minutes, raw: minutes, query: hours
  dataSyncFreq: {
    i18nKey: 'data_sync_freq',
    previousToRaw: 60,
    displayToRaw: displayMinutesToRawDataSyncFreq,
    displayValues: [3, 10, 30, 360, 1440],
    valuesWithSpecialLabel: dataSyncFreqSpecialValues,
    valuesWithDescription: [auto],
    validate: (t, value, watch) => {
      // should call react-form-hook trigger to revalidate dataFreq but trigger is not available in grafana-ui
      if (watch().dataFreq !== auto && value !== auto && value < (watch().dataFreq ?? 0) / 60) {
        return t<string>('data_sync_less_than_data_freq');
      }
      return true;
    },
  },
  co2MinSync: {
    i18nKey: 'co2_min_sync',
    previousToRaw: 1,
    displayToRaw: 1,
    displayValues: [1, 600, 800, 1500, 3000, 9999],
    valuesWithSpecialLabel: [1, 9999],
    valuesWithDescription: [auto, 1, 9999],
    translationOptions: (t, watch, previousSettings) => ({
      dataFreq: translationValue(
        t,
        'data_freq',
        dataFreqSpecialValues,
        watchWithDef('dataFreq', watch, previousSettings),
        displayMinutesToRawDataFreq
      ),
      dataSyncFreq: translationValue(
        t,
        'data_sync_freq',
        dataSyncFreqSpecialValues,
        watchWithDef('dataSyncFreq', watch, previousSettings),
        displayMinutesToRawDataSyncFreq
      ),
    }),
  },
  setupSyncFreq: {
    i18nKey: 'setup_sync_freq',
    previousToRaw: 1,
    displayToRaw: 1,
    displayValues: [1, 12, 24, 48],
    valuesWithSpecialLabel: [],
    valuesWithDescription: [auto],
  },
  rebootFreq: {
    i18nKey: 'reboot_freq',
    previousToRaw: 24,
    displayToRaw: 24,
    displayValues: [1, 7, 30],
    valuesWithSpecialLabel: [],
    valuesWithDescription: [auto],
  },
};

type SelectField = 'dataFreq' | 'dataSyncFreq' | 'co2MinSync' | 'setupSyncFreq' | 'rebootFreq';

interface SelectProps {
  fieldName: SelectField;
  errors: DeepMap<SettingsDTO, FieldError>;
  control: Control<SettingsDTO>;
  watch: UseFormWatch<SettingsDTO>;
  previousSettings: SettingsDTO;
  translationOptions?: any;
}

const defaultRawValue = (previousSettings: SettingsDTO, fieldName: SelectField) =>
  defaultValue(previousSettings, fieldName, selectDetails[fieldName].previousToRaw);

const defaultValue = (previousSettings: SettingsDTO, fieldName: SelectField, factor: number) =>
  previousSettings[fieldName] === undefined
    ? auto
    : Math.trunc(parseFloat(previousSettings[fieldName] as any) * factor);

const watchWithDef = (fieldName: SelectField, watch: UseFormWatch<SettingsDTO>, previousSettings: SettingsDTO) =>
  watch(fieldName, defaultRawValue(previousSettings, fieldName));

function SettingsSelect({ fieldName, errors, control, previousSettings, watch }: SelectProps) {
  const { t } = useTranslation();

  const toOptions = (details: SelectDetails, values: number[]) =>
    [...new Set(values)].map((v) => {
      const translationOptions = details.translationOptions
        ? details.translationOptions(t, watch, previousSettings)
        : {};
      return {
        label: t(optionPrefix(details.i18nKey, details.valuesWithSpecialLabel, v) + '_label', {
          count: v,
          maximumFractionDigits: 2,
        }),
        value: v === auto ? auto : Math.floor(v * details.displayToRaw),
        description: details.valuesWithDescription?.includes(v)
          ? t(optionPrefix(details.i18nKey, details.valuesWithSpecialLabel, v) + '_description', translationOptions)
          : undefined,
      };
    });

  const sortOptions = (options: number[]) =>
    options
      // unique
      .filter((val, index, self) => self.indexOf(val) === index)
      .sort((a, b) => a - b);

  const details = selectDetails[fieldName];
  return (
    <TooltipField
      label={t(details.i18nKey + '_label')}
      description={<Trans i18nKey={details.i18nKey + '_description'} />}
      error={errors[fieldName]}
    >
      <InputControl
        render={({ field: { onChange, ref, ...field } }) => (
          <Select
            {...field}
            onChange={(v) => onChange(v?.value)}
            options={toOptions(
              details,
              [auto].concat(
                sortOptions(
                  selectDetails[fieldName].displayValues.concat(
                    previousSettings[fieldName] !== undefined
                      ? [
                          defaultValue(
                            previousSettings,
                            fieldName,
                            selectDetails[fieldName].previousToRaw / selectDetails[fieldName].displayToRaw
                          ),
                        ]
                      : []
                  )
                )
              )
            )}
          />
        )}
        control={control}
        name={fieldName}
        rules={{
          validate: (v) => (details.validate ? details.validate(t, v, watch) : true),
        }}
        defaultValue={defaultRawValue(previousSettings, fieldName)}
      />
    </TooltipField>
  );
}

interface ColorFieldProps {
  colorName: 'color1' | 'color2' | 'color3' | 'color4';
  thresholdName?: 'co2T1' | 'co2T2' | 'co2T3' | undefined;
  errors: DeepMap<SettingsDTO, FieldError>;
  control: Control<SettingsDTO>;
  watch: UseFormWatch<SettingsDTO>;
  previousSettings: SettingsDTO;
}

function ColorFields({ colorName, thresholdName, errors, control, watch, previousSettings }: ColorFieldProps) {
  const { t } = useTranslation();
  const getStyles = () => ({
    slider: css`
      input {
        min-width: 4em;
      }
    `,
  });
  const styles = useStyles2(getStyles);
  return (
    <>
      {thresholdName && (
        <TooltipField label={t('threshold_label')} error={errors[thresholdName]}>
          <InputControl
            render={({ field }) => (
              <div className={styles.slider}>
                <Slider {...field} value={field.value} step={100} min={0} max={2000} />
              </div>
            )}
            control={control}
            name={thresholdName}
            defaultValue={previousSettings[thresholdName]}
            rules={{
              validate: (v) => {
                const nextThresholdValue = nextThreshold(thresholdName, watch());
                if (nextThresholdValue && parseInt(v, 10) > nextThresholdValue) {
                  return t<string>('threshold_more_than_next', { value: nextThresholdValue });
                }
                const previousThresholdValue = previousThreshold(thresholdName, watch());
                if (previousThresholdValue && parseInt(v, 10) < previousThresholdValue) {
                  return t<string>('threshold_less_than_previous', { value: previousThresholdValue });
                }
                return true;
              },
            }}
          />
        </TooltipField>
      )}
      <TooltipField label={t(colorName + '_label')} error={errors[colorName]}>
        <InputControl
          render={({ field: { onChange, ref, ...field } }) => (
            <ColorPicker
              {...field}
              onChange={(v) => onChange(v.substring(1).toUpperCase())}
              color={'#' + field.value || '#FFFFFF'}
              enableNamedColors={false}
              // @ts-ignore: added in custom Grafana
              disableSwatch={true}
              disableOpacity={true}
            />
          )}
          control={control}
          name={colorName}
          defaultValue={previousSettings[colorName]}
        />
      </TooltipField>
    </>
  );
}

interface DayRangeProps {
  fieldName: OpenHour;
  defaultTime: string | undefined;
  errors: DeepMap<any, FieldError>;
  register: UseFormRegister<SettingsDTO>;
  watch: UseFormWatch<SettingsDTO>;
  previousSettings: SettingsDTO;
}

function DayRange({ fieldName, defaultTime, errors, register, watch, previousSettings }: DayRangeProps) {
  const { t } = useTranslation();

  const fieldIndex = (fieldName: string) => parseInt(fieldName.charAt(3), 10);

  const associatedFieldIndex = (fieldName: string) => {
    const fieldIdx = fieldIndex(fieldName);
    return fieldIdx % 2 === 1 ? fieldIdx + 1 : fieldIdx - 1;
  };

  const validateTime = (fieldName: OpenHour, watch: UseFormWatch<SettingsDTO>, v: string | undefined | null) => {
    const fieldIdx = fieldIndex(fieldName);
    const otherFieldIdx = associatedFieldIndex(fieldName);
    const dayOfWeek = fieldName.substring(0, 3);
    const otherVal = watch()[(dayOfWeek + otherFieldIdx) as OpenHour];
    if (!v) {
      if (otherVal) {
        return t<string>('range_time_undefined_error');
      }
    } else {
      if (fieldIdx % 2 === 1 && otherVal && v.localeCompare(otherVal) > 0) {
        return t<string>('range_time_order_error');
      }
      if (fieldIdx === 2) {
        const value3 = watch()[(dayOfWeek + 3) as OpenHour];
        if (value3 && v.localeCompare(value3) > 0) {
          return t<string>('range_time_order_error');
        }
      }
    }
    return true;
  };

  return (
    <TooltipField label={fieldName.endsWith('1') ? t(fieldName + '_label') : ''} error={errors[fieldName]}>
      <Input
        defaultValue={
          previousSettings[fieldName] ?? (previousSettings[fieldName] === undefined ? defaultTime : undefined)
        }
        {...register(fieldName, { validate: (v) => validateTime(fieldName, watch, v) })}
        type="time"
        pattern="([0-1][0-9]|2[0-3]):[0-5][0-9]"
      />
    </TooltipField>
  );
}

interface DayRangesProps {
  field1: OpenHour1;
  field2: OpenHour2;
  field3: OpenHour3;
  field4: OpenHour4;
  errors: DeepMap<any, FieldError>;
  register: UseFormRegister<SettingsDTO>;
  watch: UseFormWatch<SettingsDTO>;
  previousSettings: SettingsDTO;
}
function DayRanges({ field1, field2, field3, field4, errors, register, watch, previousSettings }: DayRangesProps) {
  return (
    <HorizontalGroup>
      <DayRange
        fieldName={field1}
        defaultTime={defaultOpenHour(field1, 1)}
        errors={errors}
        register={register}
        watch={watch}
        previousSettings={previousSettings}
      />
      <span>-</span>
      <DayRange
        fieldName={field2}
        defaultTime={defaultOpenHour(field1, 2)}
        errors={errors}
        register={register}
        watch={watch}
        previousSettings={previousSettings}
      />
      <span> </span>
      <DayRange
        fieldName={field3}
        defaultTime={defaultOpenHour(field1, 3)}
        errors={errors}
        register={register}
        watch={watch}
        previousSettings={previousSettings}
      />
      <span>-</span>
      <DayRange
        fieldName={field4}
        defaultTime={defaultOpenHour(field1, 4)}
        errors={errors}
        register={register}
        watch={watch}
        previousSettings={previousSettings}
      />
    </HorizontalGroup>
  );
}

interface RadioProps {
  name: Path<SettingsDTO>;
  options: string[];
  defaultValues: SettingsDTO;
  errors: DeepMap<SettingsDTO, FieldError>;
  control: Control<SettingsDTO>;
}

export function RadioField({ name, options, defaultValues, errors, control }: RadioProps) {
  const { t } = useTranslation();
  return (
    <TooltipField label={t(name + '_label')} tooltip={t(name + '_tooltip')} error={errors[name]}>
      <InputControl
        render={({ field: { ref, ...field } }) => (
          <RadioButtonGroup {...field} options={makeI18nOptions(t, name + '_', options)} />
        )}
        control={control}
        name={name}
        defaultValue={defaultValues[name] ?? 'n'}
      />
    </TooltipField>
  );
}

interface AreaSettingsModalProps {
  area: string;
  thingUuid: string;
  // default settings are multiplied by value factor
  previousSettings: SettingsDTO;
  dashboardTimeZone: TimeZoneInfo | undefined;
  hideModal: () => void;
  setSettings: (thingUuid: string, settings: SettingsDTO, setError: (err: string) => void) => void;
  isOpen: boolean;
}

export function AreaSettingsModal({
  thingUuid,
  area,
  previousSettings,
  dashboardTimeZone,
  hideModal,
  setSettings,
  isOpen,
}: AreaSettingsModalProps) {
  const [alert, setAlert] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [s1Open, setS1Open] = useState(false);
  const { t } = useTranslation();

  const setErrorOrHide = (error: string) => {
    if (error) {
      setAlert(error);
      setSubmitted(false);
    } else {
      hideModal();
    }
  };
  const commonStyles = useStyles2(getCommonStyles);
  const getStyles = () => ({
    container: css`
      margin-bottom: 2;
    `,
    fullWidth: css`
      display: flex;
    `,
    column: css`
      width: 50%;
      padding: 5px;
    `,
    list: css`
      padding-left: 20px;
    `,
    nestedlist: css`
      padding-left: 40px;
    `,
    icon: css`
      margin-right: 0.5em;
    `,
    title: css`
      font-size: 1.6rem;
    `,
  });
  const styles = useStyles2(getStyles);

  const holidaysCountriesOptions = holidaysCountries
    .map((c) => ({
      label: t(c),
      value: c,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Modal
      title={
        <span className={styles.title}>
          <Trans i18nKey="area_settings_title" values={{ area: area }} />
        </span>
      }
      closeOnBackdropClick={false}
      onDismiss={hideModal}
      isOpen={isOpen}
    >
      <div className={styles.container}>
        <Form<SettingsDTO>
          maxWidth="none"
          validateOn="onChange"
          onSubmit={async (settings: SettingsDTO) => {
            setSubmitted(true);
            setSettings(thingUuid, settings, setErrorOrHide);
          }}
        >
          {({ register, errors, control, watch, setValue }) => (
            <>
              <h2>{t('color_h2_label')}</h2>
              <ColorFields
                colorName="color1"
                errors={errors}
                control={control}
                watch={watch}
                previousSettings={previousSettings}
              />
              <ColorFields
                colorName="color2"
                thresholdName="co2T1"
                errors={errors}
                control={control}
                watch={watch}
                previousSettings={previousSettings}
              />
              <ColorFields
                colorName="color3"
                thresholdName="co2T2"
                errors={errors}
                control={control}
                watch={watch}
                previousSettings={previousSettings}
              />
              <ColorFields
                colorName="color4"
                thresholdName="co2T3"
                errors={errors}
                control={control}
                watch={watch}
                previousSettings={previousSettings}
              />
              <h2>{t('energy_savings_h2_label')}</h2>
              <SettingsSelect
                fieldName="dataFreq"
                errors={errors}
                control={control}
                previousSettings={previousSettings}
                watch={watch}
              />
              <SettingsSelect
                fieldName="dataSyncFreq"
                errors={errors}
                control={control}
                previousSettings={previousSettings}
                watch={watch}
              />
              <SettingsSelect
                fieldName="co2MinSync"
                errors={errors}
                control={control}
                previousSettings={previousSettings}
                watch={watch}
              />
              <SettingsSelect
                fieldName="setupSyncFreq"
                errors={errors}
                control={control}
                previousSettings={previousSettings}
                watch={watch}
              />
              <SettingsSelect
                fieldName="rebootFreq"
                errors={errors}
                control={control}
                previousSettings={previousSettings}
                watch={watch}
              />
              <h2>{t('coord_h2_label')}</h2>
              <Coordinates setValue={setValue} register={register} errors={errors} defaultCoords={previousSettings} />
              <h2>{t('opening_h2_label')}</h2>
              <DayRanges
                field1="Mon1"
                field2="Mon2"
                field3="Mon3"
                field4="Mon4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Tue1"
                field2="Tue2"
                field3="Tue3"
                field4="Tue4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Wed1"
                field2="Wed2"
                field3="Wed3"
                field4="Wed4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Thu1"
                field2="Thu2"
                field3="Thu3"
                field4="Thu4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Fri1"
                field2="Fri2"
                field3="Fri3"
                field4="Fri4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Sat1"
                field2="Sat2"
                field3="Sat3"
                field4="Sat4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <DayRanges
                field1="Sun1"
                field2="Sun2"
                field3="Sun3"
                field4="Sun4"
                register={register}
                errors={errors}
                watch={watch}
                previousSettings={previousSettings}
              />
              <TooltipField label={t('timeZone_label')} error={errors.timeZone}>
                <InputControl
                  render={({ field: { onChange, ref, ...field } }) => <TimeZonePicker {...field} onChange={onChange} />}
                  control={control}
                  name="timeZone"
                  defaultValue={previousSettings.timeZone ?? defaultTimeZone(dashboardTimeZone)}
                />
              </TooltipField>
              <TooltipField label={t('holidaysCountry_label')} error={errors.holidaysCountry}>
                <InputControl
                  render={({ field: { onChange, ref, ...field } }) => (
                    <Select
                      {...field}
                      backspaceRemovesValue={true}
                      isClearable={true}
                      placeholder={t('holidaysCountry_placeholder')}
                      onChange={(v) => onChange(v?.value ? v?.value : null)}
                      options={holidaysCountriesOptions}
                    />
                  )}
                  control={control}
                  name="holidaysCountry"
                  defaultValue={previousSettings.holidaysCountry}
                />
              </TooltipField>
              <h2>{t('dcv_h2_label')}</h2>
              <RadioField
                name="s1"
                options={['n', 'v', 'b']}
                defaultValues={previousSettings}
                errors={errors}
                control={control}
              />
              {watch('s1', previousSettings.s1 ?? 'n') === 'v' && (
                <SmallFieldSet
                  label={t('s1_field_set')}
                  tooltip={
                    <Trans i18nKey="s1_v_field_set_tooltip">
                      before
                      <a
                        href="https://www.sciencedirect.com/science/article/pii/S0378778823009477#s0045"
                        {...TARGET_BLANK}
                      >
                        link
                      </a>
                      after
                    </Trans>
                  }
                >
                  <NumberField
                    field="co2Set"
                    min={600}
                    max={1500}
                    step={50}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="co2Presence"
                    min={400}
                    max={600}
                    step={50}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="dUPresence"
                    min={0}
                    max={5}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                    otherValidate={(v) =>
                      DU_PRESENCE_VALIDATE(
                        t,
                        watchWithDefault(watch, 'dUMinCo2', previousSettings),
                        v,
                        watchWithDefault(watch, 'dUMaxCo2', previousSettings)
                      )
                    }
                  />
                  <NumberField
                    i18nPrefix="v_"
                    field="dUMinCo2"
                    min={0}
                    max={5}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                    otherValidate={(v) =>
                      DU_PRESENCE_VALIDATE(
                        t,
                        v,
                        watchWithDefault(watch, 'dUPresence', previousSettings),
                        watchWithDefault(watch, 'dUMaxCo2', previousSettings)
                      )
                    }
                  />
                  <NumberField
                    i18nPrefix="v_"
                    field="dUMaxCo2"
                    min={5}
                    max={10}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                    otherValidate={(v) =>
                      DU_PRESENCE_VALIDATE(
                        t,
                        watchWithDefault(watch, 'dUMinCo2', previousSettings),
                        watchWithDefault(watch, 'dUPresence', previousSettings),
                        v
                      )
                    }
                  />
                  <Collapse
                    label={t('s1_collapse')}
                    isOpen={s1Open}
                    onToggle={setS1Open}
                    collapsible={true}
                    className={commonStyles.collapse}
                  >
                    <div className={cx([commonStyles.collapseDiv])}>
                      <NumberField
                        field="kp"
                        min={20}
                        max={100}
                        step={10}
                        defaultValues={previousSettings}
                        errors={errors}
                        register={register}
                      />
                      <NumberField
                        field="ti"
                        min={2}
                        max={16}
                        step={1}
                        defaultValues={previousSettings}
                        errors={errors}
                        register={register}
                      />
                      <NumberField
                        field="db"
                        min={20}
                        max={50}
                        step={10}
                        defaultValues={previousSettings}
                        errors={errors}
                        register={register}
                      />
                      <Button
                        variant="secondary"
                        icon="sync"
                        onClick={() =>
                          (['kp', 'ti', 'db'] as Array<keyof SettingsDTO>).forEach((k) =>
                            setValue(k, SETTINGS_DEFAULT[k])
                          )
                        }
                      >
                        {t('s1_collapse_reset')}
                      </Button>
                    </div>
                  </Collapse>
                </SmallFieldSet>
              )}
              {watch('s1', previousSettings.s1 ?? 'n') === 'b' && (
                <SmallFieldSet label={t('s1_field_set')} tooltip={t('s1_b_field_set_tooltip')}>
                  <NumberField
                    field="co2Min"
                    min={400}
                    max={800}
                    step={50}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="co2Max"
                    min={800}
                    max={3000}
                    step={50}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    i18nPrefix="b_"
                    field="dUMinCo2"
                    min={0}
                    max={5}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    i18nPrefix="b_"
                    field="dUMaxCo2"
                    min={5}
                    max={10}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                </SmallFieldSet>
              )}
              <RadioField
                name="s2"
                options={['n', 't']}
                defaultValues={previousSettings}
                errors={errors}
                control={control}
              />
              {watch('s2', previousSettings.s2 ?? 'n') !== 'n' && (
                <SmallFieldSet label={t('s2_field_set')}>
                  <NumberField
                    field="tMin"
                    min={-50}
                    max={20}
                    step={10}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="tMax"
                    min={20}
                    max={150}
                    step={10}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="dUMinS2"
                    min={0}
                    max={5}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="dUMaxS2"
                    min={5}
                    max={10}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                </SmallFieldSet>
              )}
              <RadioField
                name="s3"
                options={['n', 'h']}
                defaultValues={previousSettings}
                errors={errors}
                control={control}
              />
              {watch('s3', previousSettings.s3 ?? 'n') !== 'n' && (
                <SmallFieldSet label={t('s3_field_set')}>
                  <NumberField
                    field="rhMin"
                    min={0}
                    max={30}
                    step={10}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="rhMax"
                    min={20}
                    max={150}
                    step={10}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="dUMinS3"
                    min={0}
                    max={5}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                  <NumberField
                    field="dUMaxS3"
                    min={5}
                    max={10}
                    step={0.1}
                    defaultValues={previousSettings}
                    errors={errors}
                    register={register}
                  />
                </SmallFieldSet>
              )}
              <RadioField
                name="sIn"
                options={['y', 'n']}
                defaultValues={previousSettings}
                errors={errors}
                control={control}
              />
              <Alert title={t('area_settings_warning_title')} severity="warning">
                <Trans i18nKey="area_settings_warning_content" />
              </Alert>
              {alert && <Alert title={alert || ''} severity="error" onRemove={() => setAlert('')} />}
              <Modal.ButtonRow>
                <Button type="button" disabled={submitted} onClick={hideModal} variant="secondary">
                  {t('cancel')}
                </Button>
                <Button type="submit" disabled={submitted}>
                  {submitted ? t('submitted') : t('ok')}
                </Button>
              </Modal.ButtonRow>
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
}
