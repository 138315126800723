import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Alert, Button, Field, HorizontalGroup, Input, Tooltip, useStyles2 } from '@grafana/ui';
import React, { useState } from 'react';
import { DeepMap, FieldError, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// warning: Math.pow(10, -decimals) must be exact, not the case with 5
const LONGITUDE_LATITUDE_DECIMALS = 6;
const ALTITUDE_DECIMALS = 0;

interface Coords {
  longitude?: number;
  latitude?: number;
  altitude?: number;
}

interface Props {
  defaultCoords: Coords;
  setValue: UseFormSetValue<Coords>;
  register: UseFormRegister<Coords>;
  errors: DeepMap<Coords, FieldError>;
}

interface CoordinateProps extends Props {
  fieldName: 'latitude' | 'longitude' | 'altitude';
  suffix: string;
  min: number;
  max: number;
  decimals: number;
}

const isNumeric = (num: number | undefined) => num !== undefined && !isNaN(num);

export function geojson(coords: Coords) {
  let geometry = null;
  if (isNumeric(coords.longitude) && isNumeric(coords.latitude)) {
    const filledCoords = [coords.longitude, coords.latitude];
    if (isNumeric(coords.altitude)) {
      filledCoords.push(coords.altitude);
    }
    geometry = { type: 'Point', coordinates: filledCoords };
  }
  return { type: 'Feature', properties: null, geometry: geometry };
}

const defaultValueField = (defaultVal: number | undefined, decimals: number) =>
  defaultVal ? { defaultValue: round(defaultVal, decimals) } : {};

const round = (val: number, decimals: number) => Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals);

function Coordinate({ register, errors, defaultCoords, fieldName, suffix, min, max, decimals }: CoordinateProps) {
  const { t } = useTranslation();
  const styles = useStyles2(getStyles);

  return (
    <Field label={t(fieldName + '_label')} invalid={!!errors[fieldName]} error={errors[fieldName]?.message}>
      <Input
        suffix={suffix}
        {...defaultValueField(defaultCoords[fieldName], decimals)}
        {...register(fieldName, {
          valueAsNumber: true,
          min: min,
          max: max,
        })}
        type="number"
        step={Math.pow(10, -decimals)}
        className={styles.input}
        placeholder={t('coord_placeholder')}
      />
    </Field>
  );
}

export function Coordinates({ setValue, register, errors, defaultCoords }: Props) {
  const [alert, setAlert] = useState('');
  const { t } = useTranslation();

  const success = (pos: any) => {
    if (pos.coords?.accuracy > 50) {
      setAlert(t('set_location_accuracy_error', { accuracy: Math.trunc(pos.coords?.accuracy) }));
    } else {
      setValue('latitude', round(pos.coords?.latitude, LONGITUDE_LATITUDE_DECIMALS));
      setValue('longitude', round(pos.coords?.longitude, LONGITUDE_LATITUDE_DECIMALS));
      setValue('altitude', round(pos.coords?.altitude, ALTITUDE_DECIMALS));
    }
  };
  const error = (err: any) => setAlert(t('set_location_error', { error: err.message }));
  const onSetLocationClick = () => {
    navigator.geolocation.getCurrentPosition(success, error, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    });
    return undefined;
  };
  return (
    <>
      {alert && <Alert title={alert || ''} severity="error" onRemove={() => setAlert('')} />}
      <HorizontalGroup wrap={true}>
        <Tooltip placement="bottom-start" content={t('set_location_tooltip')}>
          <Button type="button" fill="outline" variant="secondary" icon="compass" onClick={() => onSetLocationClick()}>
            {t('set_location_label')}
          </Button>
        </Tooltip>
        <HorizontalGroup>
          <Coordinate
            setValue={setValue}
            register={register}
            errors={errors}
            defaultCoords={defaultCoords}
            fieldName="latitude"
            suffix=" °"
            min={-90}
            max={90}
            decimals={LONGITUDE_LATITUDE_DECIMALS}
          />
          <Coordinate
            setValue={setValue}
            register={register}
            errors={errors}
            defaultCoords={defaultCoords}
            fieldName="longitude"
            suffix=" °"
            min={-180}
            max={180}
            decimals={LONGITUDE_LATITUDE_DECIMALS}
          />
          <Coordinate
            setValue={setValue}
            register={register}
            errors={errors}
            defaultCoords={defaultCoords}
            fieldName="altitude"
            suffix=" m"
            min={0}
            max={9999}
            decimals={ALTITUDE_DECIMALS}
          />
        </HorizontalGroup>
      </HorizontalGroup>
    </>
  );
}

function getStyles(theme: GrafanaTheme2) {
  return {
    input: css`
      width: ${LONGITUDE_LATITUDE_DECIMALS + 3}rem;
    `,
  };
}
